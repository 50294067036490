import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { TransitionOpacityBlock } from '@blocks/transition-opacity';
import { EntityCreateFeature } from '@features/entity-create';
import { EntityListFeature } from '@features/entity-list';
import { EntityOneFeature } from '@features/entity-one';
import { LayoutBaseFeature } from '@features/layout-base'
import { getChildrenEntitiesByKey } from '@helpers/get-children-entities-by-key';
import { getEntityByKey } from '@helpers/get-entity-by-key.helper';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { setBreadcrumbs } from '@stores/app/app.slice';
import { getUserDictionaryAction } from '@stores/user/user.actions';
import { BreadcrumbType } from '@type/app/breadcrumb.type';

import styles from './entity.module.scss';


type PathElement = {
  key: string;
  value: 'list' | 'create' | string;
}

const getPathElementsByPathname = (pathname: string): PathElement[] => {
  const pathList = pathname.split('/').slice(1);
  return pathList.map((item) => {
    const separatorIndex = item.lastIndexOf('-');

    return {
      key: item.slice(0, separatorIndex),
      value: item.slice(separatorIndex + 1),
    };
  })
  .filter((element) => element.key && element.value);
}

const getBreacrumbsByPathElements = (pathElements: PathElement[]): BreadcrumbType[] => {
  const breadcrumbs = pathElements.map((element, index) => {
    const entity = getEntityByKey(element.key);
    const title = element.value === 'list'
      ? entity.label.plural
      : element.value === 'create'
        ? `Добавить ${entity.label.genitive.toLowerCase()}`
        : `${entity.label.singular} №${element.value}`;
    const link = '/' + pathElements.slice(0, index + 1).map((element) => element.key + '-' + element.value).join('/')

    return { title, link };
  });

  return [{ title: 'Главная', link: '/' }, ...breadcrumbs];
}

const EntityPage = () => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.auth.user!.role);
  const location = useLocation();
  const pathElements = getPathElementsByPathname(location.pathname);
  const breadcrumbs = getBreacrumbsByPathElements(pathElements);

  const currentElement = pathElements[pathElements.length - 1];
  const parentElement = pathElements[pathElements.length - 2];
  
  const currentEntity = getEntityByKey(currentElement.key);
  const childrenEntities = getChildrenEntitiesByKey(currentElement.key);

  useEffect(() => {
    dispatch(setBreadcrumbs(breadcrumbs));
  }, [dispatch, location.pathname, breadcrumbs]);

  useEffect(() => {
    dispatch(getUserDictionaryAction());
  }, [dispatch]);

  return (
    <LayoutBaseFeature>
      <TransitionOpacityBlock>
        <div className="row">
          <div className="col-sm-12">
            <div className={styles['entity']}>
              {currentElement.value === 'list' && (
                <div className={styles['entity__block']}>
                  <EntityListFeature entity={currentEntity} />
                </div>
              )}
              {currentElement.value === 'create' && (
                <TransitionOpacityBlock>
                  <div className={styles['entity__block']}>
                    <EntityCreateFeature entity={currentEntity} parentId={Number(parentElement.value)} />
                  </div>
                </TransitionOpacityBlock>
              )}
              {currentElement.value !== 'list' && currentElement.value !== 'create' && (
                <>
                  <div className={styles['entity__block']}>
                    <EntityOneFeature entity={currentEntity} id={Number(currentElement.value)} />
                  </div>

                  {childrenEntities.map((entity) => {
                    const visible =
                      role[`${entity.key}_CREATE`] !== 'NEVER' ||
                      role[`${entity.key}_UPDATE`] !== 'NEVER' ||
                      role[`${entity.key}_READ`] !== 'NEVER' ||
                      role[`${entity.key}_DELETE`] !== 'NEVER';

                    return (
                      <div className={styles['entity__block']} key={entity.key}>
                        {(entity.type === 'one' && visible) && <EntityOneFeature isChildren entity={entity} parentId={Number(currentElement.value)} />}
                        {(entity.type === 'list' && visible) && <EntityListFeature entity={entity} parentId={Number(currentElement.value)} />}
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      </TransitionOpacityBlock>
    </LayoutBaseFeature>
  );
};

export { EntityPage };
