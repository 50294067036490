import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { LayoutBaseFeature } from '@features/layout-base'
import { UserDetailFeature } from '@features/user-detail';
import { UserUpdateFeature } from '@features/user-update';
import { setBreadcrumbs } from '@stores/app/app.slice';
import { getUserOneAction } from '@stores/user/user.actions';
import { getDepartmentDictionaryAction } from '@stores/department/department.actions';
import { getRoleDictionaryAction } from '@stores/role/role.actions';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { ButtonBackBlock } from '@blocks/button-back';
import { CardDataBlock } from '@blocks/card-data';
import { ButtonEditBlock } from '@blocks/button-edit';
import { ButtonCancelBlock } from '@blocks/button-cancel';
import { TransitionOpacityBlock } from '@blocks/transition-opacity';

import { UserDetailPageParams } from './user-detail.page-params';


const UserDetailPage = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const loading = useAppSelector((state) => {
    return state.user.status === 'loading' || state.department.status === 'loading' || state.role.status === 'loading';
  });
  const hasUpdateAccess = useAppSelector((state) => state.auth.user?.role.USER_UPDATE !== 'NEVER');
  const { id } = useParams<UserDetailPageParams>();
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { title: 'Главная', link: '/' },
      { title: 'Пользователи', link: '/user-list' },
      { title: 'Пользователь №' + id, link: '' },
    ]));

    dispatch(getUserOneAction(Number(id)));
    dispatch(getDepartmentDictionaryAction());
    dispatch(getRoleDictionaryAction());
  }, [dispatch, id]);

  return (
    <LayoutBaseFeature>
      <CardDataBlock
        title={'Пользователь №' + id}
        extra={
          <>
            {isEditing
              ? (
                <ButtonCancelBlock onClick={() => setIsEditing(false)} />
              )
              : hasUpdateAccess && (
                <ButtonEditBlock onClick={() => setIsEditing(true)} />
              )
            }
            {!isEditing && <ButtonBackBlock />}
          </>
        }
        loading={loading}
      >
        {isEditing && (
          <TransitionOpacityBlock>
            <UserUpdateFeature onDone={() => setIsEditing(false)} />
          </TransitionOpacityBlock>
        )}
        {user.current && !isEditing && (
          <TransitionOpacityBlock>
            <UserDetailFeature />
          </TransitionOpacityBlock>
        )}
      </CardDataBlock>
    </LayoutBaseFeature>
  );
};

export { UserDetailPage };
