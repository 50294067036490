import { ColumnType } from '@type/config/column.type';
import { checkVisibleField } from './check-visible-field.helper';


export const calculateProgress = (formValue: { [key: string]: any } | null, columns: ColumnType[]): [number, number] => {
  let totalCompletedFields = 0;
  let totalReqFields = 0;

  const requiredCheck = (column: ColumnType): boolean => {
    const calculatedField = column.required || column.counted;

    if (calculatedField && !column.visibleRule) {
      return true;
    }

    if (!formValue) {
      return false;
    }

    const visibleField = checkVisibleField(column, formValue);

    if (calculatedField && visibleField) {
      return true
    }
    
    return false;
  }

  columns.forEach((column) => {
    const isRequiredField = requiredCheck(column);

    if (isRequiredField) {
      totalReqFields += 1;

      const filled = formValue
        && formValue[column.key] !== undefined
        && formValue[column.key] !== null
        && formValue[column.key] !== ''
        && (Array.isArray(formValue[column.key]) ? formValue[column.key].length > 0 : true)

      if (filled) {
        totalCompletedFields += 1;
      }
    }
  });

  return [totalCompletedFields, totalReqFields];
}
