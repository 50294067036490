import { booleanTranslate } from './boolean-translate.helper';
import { dateTranslate } from './date-translate.helper';
import { datetimeTranslate } from './datetime-translate.helper';
import { ColumnType } from '@type/config/column.type';


export const buildColumnValue = (column: ColumnType, value: any): any => {
  if (column.type === 'date') {
    return dateTranslate(value);
  }

  if (column.type === 'datetime') {
    return datetimeTranslate(value);
  }

  if (column.type === 'boolean') {
    return booleanTranslate(value);
  }

  if (column.type === 'multiselect') {
    return Array.isArray(value) ? value.join(', ') : value;
  }

  return value;
}
