import { Link } from "react-router-dom";
import { Typography } from '@mui/material';

import { useAppSelector } from '@hooks/redux.hooks';

import styles from './not-found.module.scss';


const NotFoundPage = () => {
  const auth = useAppSelector((state) => state.auth);

  return (
    <div className={styles['not-found-page']}>
      <div className={styles['not-found-page__content']}>
        <div className={styles['not-found-page__emoji']}></div>
        <Typography variant="h1">🤷‍♂️ Упс!</Typography>
        <Typography variant="body1">Такой страницы не существует в текущей реальности..</Typography>
        <div className={styles['not-found-page__links']}></div>
        {auth.isLoggedIn ? (
          <Link to="/">На главную</Link>
        ) : (
          <Link to="/signin">Авторизация</Link>
        )}
      </div>
    </div>
  );
};

export { NotFoundPage };
