import { EntityType } from '@type/config/entity.type';

export const pcrPapTestCherezGodEntity: EntityType = {
  key: 'pcr_pap_test_cherez_god',
  label: {
    singular: 'ПЦР ПАП тест через год',
    plural: 'ПЦР ПАП тест через год',
    genitive: 'ПЦР ПАП тест через год',
  },
  type: 'one',
  parent: 'card',
  columns: [
    {
      label: 'ВПЧ положительный',
      key: 'vpch-polozhitelnyj',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Выделенные генотипы',
      key: 'vydelennye-genotipy',
      type: 'multiselect',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: [
        '16',
        '18',
        '31',
        '33',
        '35',
        '39',
        '45',
        '51',
        '52',
        '56',
        '58',
        '59',
      ],
      visibleRule: {
        fieldKey: 'vpch-polozhitelnyj',
        equal: true,
      },
    },
    {
      label: 'Активность (число копий)',
      key: 'aktivnost-chislo-kopij',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      visibleRule: {
        fieldKey: 'vpch-polozhitelnyj',
        equal: true,
      },
    },
    {
      label: 'Цитология',
      key: 'citologiya',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: [
        'NILM',
        'ASC-US',
        'ASC-H',
        'LSIL (CIN I)',
        'HSIL (CIN II/III)',
        'CANCER',
      ],
    },
  ],
}
