import { configDcs } from '@config/config-dcs';
import { getPathsKeys } from './get-paths-keys.helper';
import { getEntityByKey } from './get-entity-by-key.helper';


export const pathsBuild = (): string[] => {
  const rootKeys = configDcs.entities
    .filter((entity) => entity.parent === 'root')
    .map((entity) => entity.key);
  const pathsKeys = getPathsKeys();
  const paths: string[] = [];

  rootKeys.forEach((rootKey) => {
    const rootPath = `/${getEntityByKey(rootKey).key}-list`;
    paths.push(rootPath);

    pathsKeys.forEach((pathKeys) => {
      const keyIndex = pathKeys.indexOf(rootKey);

      if (keyIndex !== -1) {
        const keysByIndex = pathKeys.slice(keyIndex);
        const path = rootPath + keysByIndex.reduce((acc, el) => acc + `/${el}-:${el}Id`, '');
        paths.push(path);
      }
    });
  });

  return paths;
}
