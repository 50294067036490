import { AsteriskProps } from './asterisk.props';


const AsteriskBlock = ({ required, counted }: AsteriskProps) => {
  if (required) {
    return (
      <span style={{color: 'red'}}>*</span>
    );
  }

  if (counted) {
    return (
      <span style={{color: 'blue'}}>*</span>
    );
  }

  return null;
}

export { AsteriskBlock };
