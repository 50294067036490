import cs from 'classnames';

import { EntityActionsBlock } from '@blocks/entity-actions';
import { configDcs } from '@config/index';

import styles from './entity-widgets.module.scss';
import { useAppSelector } from '@hooks/redux.hooks';


const EntityWidgetsFeature = () => {
  const entityList = configDcs.entities.filter((entity) => entity.parent === 'root');
  const role = useAppSelector((state) => state.auth.user!.role);

  return (
    <div className={cs('row', styles['entity-list'])}>
      {(role.DEPARTMENT_READ !== 'NEVER' || role.DEPARTMENT_CREATE !== 'NEVER') && (
        <div className={cs('col-sm-3', styles['entity-list__item'])}>
          <EntityActionsBlock
            title="Подразделения"
            linkList="/department-list"
            linkCreate="/department-list/department-create"
            canCreate={role.DEPARTMENT_CREATE !== 'NEVER'}
            canRead={role.DEPARTMENT_READ !== 'NEVER'}
          />
        </div>
      )}
      {(role.ROLE_READ !== 'NEVER' || role.ROLE_CREATE !== 'NEVER') && (
        <div className={cs('col-sm-3', styles['entity-list__item'])}>
          <EntityActionsBlock
            title="Роли"
            linkList="/role-list"
            linkCreate="/role-list/role-create"
            canCreate={role.ROLE_CREATE !== 'NEVER'}
            canRead={role.ROLE_READ !== 'NEVER'}
          />
        </div>
      )}
      {(role.USER_READ !== 'NEVER' || role.USER_CREATE !== 'NEVER') && (
        <div className={cs('col-sm-3', styles['entity-list__item'])}>
          <EntityActionsBlock
            title="Пользователи"
            linkList="/user-list"
            linkCreate="/user-list/user-create"
            canCreate={role.USER_CREATE !== 'NEVER'}
            canRead={role.USER_READ !== 'NEVER'}
          />
        </div>
      )}
      {entityList.map((entity) => (role[`${entity.key}_READ`] !== 'NEVER' || role[`${entity.key}_CREATE`] !== 'NEVER') ? (
        <div
          key={entity.key}
          className={cs('col-sm-3', styles['entity-list__item'])}
        >
          <EntityActionsBlock
            title={entity.label.plural}
            linkList={`/${entity.key}-list`}
            linkCreate={`/${entity.key}-list/${entity.key}-create`}
            canCreate={role[`${entity.key}_CREATE`] !== 'NEVER'}
            canRead={role[`${entity.key}_READ`] !== 'NEVER'}
          />
        </div>
      ) : null )}
    </div>
  );
}

export { EntityWidgetsFeature };
