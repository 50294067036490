import { ConfigType } from '@type/config/config.type';

import { cardEntity } from './card.entity';
import { kolposkopiyaCherezGodEntity } from './kolposkopiya-cherez-god.entity';
import { kolposkopiyaEntity } from './kolposkopiya.entity';
import { mikrobiotaCherezGodEntity } from './mikrobiota-cherez-god.entity';
import { mikrobiotaEntity } from './mikrobiota.entity';
import { mutaciiCherezGodEntity } from './mutacii-cherez-god.entity';
import { mutaciiEntity } from './mutacii.entity';
import { pcrPapTestCherezGodEntity } from './pcr-pap-test-cherez-god.entity';
import { pcrPapTestEntity } from './pcr-pap-test.entity';
import { priemCherezGodEntity } from './priem-cherez-god.entity';
import { priemOsmotrEntity } from './priem-osmotr.entity';


export const configDcs: ConfigType = {
  appTitle: 'DS39HPV',
  entities: [
    cardEntity,
    priemOsmotrEntity,
    kolposkopiyaEntity,
    mikrobiotaEntity,
    mutaciiEntity,
    pcrPapTestEntity,
    priemCherezGodEntity,
    kolposkopiyaCherezGodEntity,
    mikrobiotaCherezGodEntity,
    pcrPapTestCherezGodEntity,
    mutaciiCherezGodEntity,
  ],
};
