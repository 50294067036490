import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from '@hooks/redux.hooks';

import { AuthGuardProps } from './auth-guard.props';


const AuthGuardHoc = ({ children }: AuthGuardProps) => {
  const location = useLocation();
  const auth = useAppSelector((state) => state.auth);

  if (!auth.isLoggedIn) {
    return (<Navigate to='/signin' state={{ from: location }} />);
  }

  return children;
}

export { AuthGuardHoc }