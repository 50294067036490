import { createSlice } from '@reduxjs/toolkit';

import { BackendErrorsType } from '@type/shared/backend-errors.type';
import { RoleStateInterface } from '@type/role/role-state.interface';
import { createRoleAction, getRoleDictionaryAction, getRoleListAction, getRoleOneAction, updateRoleAction } from './role.actions';


const initialState: RoleStateInterface = {
  list: [],
  dictionary: [],
  current: null,
  status: 'idle',
  errors: null,
}

const roleSlice = createSlice({
  name: '@@role',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoleListAction.pending, (state) => {
        state.status = 'loading';
        state.errors = null;
        state.list = [];
      })
      .addCase(getRoleListAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload;
      })
      .addCase(getRoleListAction.rejected, (state) => {
        state.status = 'idle';
      })

      .addCase(getRoleDictionaryAction.pending, (state) => {
        state.status = 'loading';
        state.errors = null;
        state.list = [];
      })
      .addCase(getRoleDictionaryAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.dictionary = action.payload;
      })
      .addCase(getRoleDictionaryAction.rejected, (state) => {
        state.status = 'idle';
      })

      .addCase(getRoleOneAction.pending, (state) => {
        state.status = 'loading';
        state.errors = null;
        state.current = null;
      })
      .addCase(getRoleOneAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.current = action.payload;
      })
      .addCase(getRoleOneAction.rejected, (state) => {
        state.status = 'idle';
      })

      .addCase(createRoleAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(createRoleAction.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(createRoleAction.rejected, (state, action) => {
        state.status = 'error';
        state.errors = action.payload as BackendErrorsType;
      })

      .addCase(updateRoleAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(updateRoleAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.current = action.payload
      })
      .addCase(updateRoleAction.rejected, (state, action) => {
        state.status = 'error';
        state.errors = action.payload as BackendErrorsType;
      });
  },
});

export default roleSlice.reducer;
