import { EntityType } from '@type/config/entity.type';

export const mikrobiotaCherezGodEntity: EntityType = {
  key: 'mikrobiota_cherez_god',
  label: {
    singular: 'Микробиота через год',
    plural: 'Микробиота через год',
    genitive: 'Микробиота через год',
  },
  type: 'one',
  parent: 'card',
  columns: [
    {
      label: 'Количество ДНК Человека (ГЭ/мл)',
      key: 'kolichestvo-dnk-cheloveka-geh-ml',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Количество ДНК бактерий (ГЭ/мл)',
      key: 'kolichestvo-dnk-bakterij-geh-ml',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'L. spp (ГЭ/мл)',
      key: 'l-spp-geh-ml',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'G.vaginalis (ГЭ/мл)',
      key: 'g-vaginalis-geh-ml',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'A. vaginae (ГЭ/мл)',
      key: 'a-vaginae-geh-ml',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Enterobacteriacea (ГЭ/мл)',
      key: 'enterobacteriacea-geh-ml',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Staphylococcus spp. (ГЭ/мл)',
      key: 'staphylococcus-spp-geh-ml',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Streptococcus spp. (ГЭ/мл)',
      key: 'streptococcus-spp-geh-ml',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'U.parvum (ГЭ/мл)',
      key: 'u-parvum-geh-ml',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'U.urealiticum (ГЭ/мл)',
      key: 'u-urealiticum-geh-ml',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'M.hominis (ГЭ/мл)',
      key: 'm-hominis-geh-ml',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'C. albicans (ГЭ/мл)',
      key: 'c-albicans-geh-ml',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'C. glabrata (ГЭ/мл)',
      key: 'c-glabrata-geh-ml',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'C. crusei (ГЭ/мл)',
      key: 'c-crusei-geh-ml',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'C.parapsilois и tropicalis сумм. (ГЭ/мл)',
      key: 'c-parapsilois-i-tropicalis-summ-geh-ml',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Ch. trachomatis (ГЭ/мл)',
      key: 'ch-trachomatis-geh-ml',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Mycoplasma urogenitalis (ГЭ/мл)',
      key: 'mycoplasma-urogenitalis-geh-ml',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Trichomonas vaginalis (ГЭ/мл)',
      key: 'trichomonas-vaginalis-geh-ml',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
  ],
}
