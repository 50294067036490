import { EntityType } from '@type/config/entity.type';

export const cardEntity: EntityType = {
  key: 'card',
  label: {
    singular: 'Карта',
    plural: 'Карты',
    genitive: 'Карту',
  },
  type: 'list',
  parent: 'root',
  columns: [
    {
      label:'Номер амбулаторной карты',
      key: 'nomer-ambulatornoj-karty',
      type: 'text',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      required: true,
      search: true,
    },
    {
      label:'Номер пробирки (первичный прием)',
      key: 'nomer-probirki-pervichnyj-priem',
      type: 'text',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      required: true,
      search: true,
    },
    {
      label:'Номер пробирки (контроль)',
      key: 'nomer-probirki-kontrol',
      type: 'text',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      search: true,
    },
    {
      label:'Фамилия',
      key: 'familiya',
      type: 'text',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      required: true,
      search: true,
    },
    {
      label:'Имя',
      key: 'imya',
      type: 'text',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      search: true,
      required: true,
    },
    {
      label:'Отчество',
      key: 'otchestvo',
      type: 'text',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      search: true,
      counted: true,
    },
    {
      label:'Дата рождения',
      key: 'data-rozhdeniya',
      type: 'date',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      required: true,
    },
    {
      label:'Адрес места проживания',
      key: 'adres-mesta-prozhivaniya',
      type: 'text',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      required: true,
    },
    {
      label:'Электронная почта',
      key: 'ehlektronnaya-pochta',
      type: 'text',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label:'Мобильный телефон',
      key: 'mobilnyj-telefon',
      type: 'phone',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      required: true,
    },
  ]
}