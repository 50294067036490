import { configDcs } from '@config/config-dcs';

export const getPathsKeys = (): string[][] => {
  return configDcs.entities
    .filter((entity) => entity.type === 'list')
    .map((entity) => {
      const path: string[] = [entity.key];
      let parentKey = entity.parent;

      while(true) {
        if (parentKey === 'root') {
          break;
        }

        path.unshift(parentKey);
        parentKey = configDcs.entities.find((entity) => entity.key === parentKey)!.parent; // eslint-disable-line
      }

      return path;
    });
}
