import { Link } from 'react-router-dom';

import { Breadcrumbs, Card } from '@mui/material';

import styles from './breadcrumbs.module.scss';
import { useAppSelector } from '@hooks/redux.hooks';


const BreadcrumbsFeature = () => {
  const breadcrumbs = useAppSelector((state) => state.app.breadcrumbs);

  return (
    <Card className={styles['breadcrumbs']}>
      <div className={styles['breadcrumbs__body']}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs.map((item, index) => {
            const lastItem = index === breadcrumbs.length - 1

            return (
              lastItem
                ? (
                  <span key={item.title}>{item.title}</span>
                )
                : (
                  <Link key={item.title} to={item.link}>{item.title}</Link>
                )
            )
          })}
        </Breadcrumbs>
      </div>
    </Card>
  );
};

export { BreadcrumbsFeature };
