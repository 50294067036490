import { CircularProgress } from '@mui/material';

import { useAppSelector } from '@hooks/redux.hooks';

import styles from './app-loader.module.scss';


const AppLoaderFeature = () => {
  const { loading } = useAppSelector((state) => state.app);

  return loading ? (
    <div className={styles['app-loader']}>
      <CircularProgress />
    </div>
  ) : null;
}

export { AppLoaderFeature };
