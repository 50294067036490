import { HttpService } from '@services/http.service';
import { BaseResponseInterface } from '@type/shared/base-response.interface';
import { CreateUserRequestInterface } from '@type/user/create-user-request.interface';
import { UpdateUserRequestInterface } from '@type/user/update-user-request.interface';
import { UserType } from '@type/user/user.type';


export class UserApi {
  public static async create(
    payload: CreateUserRequestInterface,
  ): Promise<BaseResponseInterface<{ user: UserType }>> {
    return await HttpService.sendRequest('POST', '/user/create', {
      user: payload,
    });
  };

  public static async getList(): Promise<BaseResponseInterface<{ users: UserType[] }>> {
    return await HttpService.sendRequest('GET', '/user/list');
  };

  public static async getDictionary(): Promise<BaseResponseInterface<{ users: UserType[] }>> {
    return await HttpService.sendRequest('GET', '/user/dictionary');
  };

  public static async getOne(id: number): Promise<BaseResponseInterface<{ user: UserType }>> {
    return await HttpService.sendRequest('GET', '/user/one/' + id);
  };

  public static async update(
    payload: UpdateUserRequestInterface,
  ): Promise<BaseResponseInterface<{ user: UserType }>> {
    return await HttpService.sendRequest('PUT', '/user/update', {
      user: payload,
    });
  };
};
