import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { currentUserAction } from '@stores/auth/auth.actions';

import { AppStateInterface } from '@type/app/app-state.interface';
import { BreadcrumbType } from '@type/app/breadcrumb.type';
import { NotifyType } from '@type/app/notify.type';


const initialState: AppStateInterface = {
  loading: true,
  notify: {
    type: 'success',
    message: null,
  },
  breadcrumbs: [],
}

const appSlice = createSlice({
  name: '@@app',
  initialState,
  reducers: {
    callNotifyAction: (state, action: PayloadAction<NotifyType>) => ({
      ...state,
      notify: {
        type: action.payload.type,
        message: action.payload.message,
      },
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
    setBreadcrumbs: (state, action: PayloadAction<BreadcrumbType[]>) => ({
      ...state,
      breadcrumbs: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(currentUserAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(currentUserAction.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(currentUserAction.rejected, (state) => {
        state.loading = false;
      });
  }
});

export default appSlice.reducer;

export const {
  callNotifyAction,
  setLoading,
  setBreadcrumbs,
} = appSlice.actions;
