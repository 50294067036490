import { PersistanceService } from '@services/persistance.service';
import { BaseResponseInterface } from '@type/shared/base-response.interface';
import { apiUrl } from '../../config';


type methodType = 'GET' | 'POST' | 'PUT' | 'DELETE';

type optionsType = {
  method: methodType;
  headers: {
    'Content-Type': string;
    'Authorization'?: string;
  };
  body: any;
};


export class HttpService {
  private static getOptions(method: methodType, payload: any): optionsType {
    const token = PersistanceService.getToken();

    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: (method === 'GET' || method === 'DELETE') ? null : JSON.stringify(payload),
    };

    return options;
  };

  private static getParams(method: methodType, payload: any): string {
    if ((method === 'POST' || method === 'PUT' ) || typeof payload !== 'object') {
      return '';
    }
    
    const keys = Object.keys(payload);
    let params = '?';

    keys.forEach((key) => {
      if (payload[key]) {
        params += key + '=' + payload[key] + '&';
      }
    });

    if (params[params.length - 1] === '&') {
      params = params.slice(0, -1);
    }

    return params;
  }

  public static async sendRequest(
    method: methodType,
    url: string,
    payload?: any
  ): Promise<BaseResponseInterface<any>> {
    const options = this.getOptions(method, payload);
    const params = this.getParams(method, payload);

    try {
      const response = await fetch(apiUrl + url + params, options);

      const data = JSON.parse(await response.text())

      if (data.errors || (data.message && (data.statusCode && data.statusCode !== 403))) {
        return {
          success: false,
          errors: data.errors || data.message,
          data: null,
        }
      }

      return { data: { ...data }, success: true };
    } catch (error) {
      console.error(`${method}: ${url} - Failed to fetch`, error)
      return {
        success: false,
        data: null,
        errors: { server: [`${method}: ${apiUrl + url} - Failed to fetch`] },
      };
    }
  };
}
