import { HttpService } from '@services/http.service';
import { BaseResponseInterface } from '@type/shared/base-response.interface';
import { DepartmentType } from '@type/department/department.type';
import { CreateDepartmentRequestInterface } from '@type/department/create-department-request.interface';


export class DepartmentApi {
  public static async create(
    payload: CreateDepartmentRequestInterface,
  ): Promise<BaseResponseInterface<{ department: DepartmentType }>> {
    return await HttpService.sendRequest('POST', '/department/create', {
      department: payload,
    });
  };

  public static async getList(): Promise<BaseResponseInterface<{ departments: DepartmentType[] }>> {
    return await HttpService.sendRequest('GET', '/department/list');
  };

  public static async getDictionary(): Promise<BaseResponseInterface<{ departments: DepartmentType[] }>> {
    return await HttpService.sendRequest('GET', '/department/dictionary');
  };

  public static async getOne(id: number): Promise<BaseResponseInterface<{ department: DepartmentType }>> {
    return await HttpService.sendRequest('GET', '/department/one/' + id);
  };

  public static async update(
    payload: DepartmentType,
  ): Promise<BaseResponseInterface<{ department: DepartmentType }>> {
    return await HttpService.sendRequest('PUT', '/department/update', {
      department: payload,
    });
  };
};
