import { SyntheticEvent, useState } from 'react';
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import RotateRightIcon from '@mui/icons-material/RotateRight';

import { PaginationProps } from './pagination.props';
import styles from './pagination.module.scss';


const buildPaginationList = (current: number, count: number) => {
  const paginationList = [];

  for (let i = 1; i <= count; i++) {
    if (
      i === 1
      || i === count
      || i === current
      || i === current + 1
      || i === current + 2
      || i === current - 1
      || i === current - 2
    ) {
      paginationList.push({
        page: i,
        disabled: i === current,
        dots: false,
      });
    }

    if (i === current + 3 || i === current - 3) {
      paginationList.push({
        page: Date.now() - i,
        disabled: true,
        dots: true,
      });
    }
  }

  return paginationList;
}

const PaginationBlock = ({ current, count, setCurrent }: PaginationProps) => {
  const [currentPage, setCurrentPage] = useState(String(current));
  const paginationList = buildPaginationList(Number(current), count);

  return (
    <div className={styles['pagination']}>
      <div className={styles['pagination__form']}>
        <form
          onSubmit={(e: SyntheticEvent) => {
            e.preventDefault();

            if (Number(currentPage) > count || Number(currentPage) < 1 || Number(currentPage) === current) {
              return;
            }

            setCurrent(Number(currentPage));
          }}
        >
          <FormControl variant="outlined">
            <InputLabel htmlFor="pagination-search-input">Перейти на страницу</InputLabel>
            <OutlinedInput
              id="pagination-search-input"
              type="number"
              value={currentPage}
              onChange={(e) => setCurrentPage(e.target.value)}
              name="pagination-search-input"
              // size="small"
              label="Перейти на страницу"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setCurrent(Number(currentPage))}
                    disabled={Number(currentPage) > count || Number(currentPage) < 1 || Number(currentPage) === current}
                  >
                    <RotateRightIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </form>
      </div>
      <div className={styles['pagination__buttons']}>
        {paginationList.map((item) => (
          <Button
            key={item.page}
            size={item.dots ? 'small' : 'large'}
            disabled={item.disabled}
            onClick={() => {
              setCurrent(item.page);
              setCurrentPage(String(item.page));
            }}
          >
            {item.dots ? '..' : item.page}
          </Button>
        ))}
      </div>
      <div className={styles['pagination__display']}>
        {current}/{count}
      </div>
    </div>
  );
}

export { PaginationBlock };
