import { useEffect } from 'react';

import { CardDataBlock } from '@blocks/card-data';
import { LayoutBaseFeature } from '@features/layout-base'
import { RoleCreateFeature } from '@features/role-create';
import { useAppDispatch } from '@hooks/redux.hooks';
import { setBreadcrumbs } from '@stores/app/app.slice';
import { ButtonBackBlock } from '@blocks/button-back';


const RoleCreatePage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { title: 'Главная', link: '/' },
      { title: 'Роли', link: '/role-list' },
      { title: 'Добавить роль', link: '' },
    ]))
  }, [dispatch])

  return (
    <LayoutBaseFeature>
      <div className="row">
        <div className="col-sm-12">
          <CardDataBlock
            title="Добавить роль"
            extra={<ButtonBackBlock />}
          >
            <RoleCreateFeature />
          </CardDataBlock>
        </div>
      </div>
    </LayoutBaseFeature>
  );
};

export { RoleCreatePage };
