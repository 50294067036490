import { useEffect } from 'react';

import { LayoutBaseFeature } from '@features/layout-base';
import { UserListFeature } from '@features/user-list';
import { useAppDispatch } from '@hooks/redux.hooks';
import { setBreadcrumbs } from '@stores/app/app.slice';


const UserListPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { title: 'Главная', link: '/' },
      { title: 'Пользователи', link: '' },
    ]))
  }, [dispatch]);

  return (
    <LayoutBaseFeature>
      <div className="row">
        <div className="col-sm-12">
          <UserListFeature />
        </div>
      </div>
    </LayoutBaseFeature>
  );
};

export { UserListPage };
