import SaveIcon from '@mui/icons-material/Save';

import { ButtonSaveProps } from './button-save.props';
import { LoadingButton } from '@mui/lab';


const ButtonSaveBlock = ({ onClick, loading, disabled }: ButtonSaveProps) => {
  return (
    <LoadingButton
      loading={loading}
      type='submit'
      onClick={onClick}
      size='large'
      startIcon={<SaveIcon />}
      variant="contained"
      color="primary"
      disabled={disabled}
    >
      Сохранить
    </LoadingButton>
  );
};

export { ButtonSaveBlock };
