import { TextField } from '@mui/material';

import { FormFieldBlock } from '@blocks/form-field';
import { AsteriskBlock } from '@blocks/asterisk';

import { FieldDecimalProps } from './field-decimal.props';


const handleWheelPrevent = (e: any) => e.preventDefault();

const FieldDecimalBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  required,
  counted,
}: FieldDecimalProps) => {
  return (
    <FormFieldBlock error={error}>
      <TextField
        label={<>{label}<AsteriskBlock required={required} counted={counted} /></>}
        name={name}
        value={value}
        onChange={onChange}
        type="number"
        variant="filled"
        fullWidth
        onFocus={(e) => e.target.addEventListener('wheel', handleWheelPrevent)}
        onBlur={(e) => e.target.removeEventListener('wheel', handleWheelPrevent)}
      />
    </FormFieldBlock>
  );
};

export { FieldDecimalBlock };
