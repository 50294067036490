import { HttpService } from '@services/http.service';
import { BaseResponseInterface } from '@type/shared/base-response.interface';
import { CreateRoleRequestInterface } from '@type/role/create-role-request.interface';
import { RoleType } from '@type/role/role.type';


export class RoleApi {
  public static async create(
    payload: CreateRoleRequestInterface,
  ): Promise<BaseResponseInterface<{ role: RoleType }>> {
    return await HttpService.sendRequest('POST', '/role/create', {
      role: payload,
    });
  };

  public static async getList(): Promise<BaseResponseInterface<{ roles: RoleType[] }>> {
    return await HttpService.sendRequest('GET', '/role/list');
  };

  public static async getDictionary(): Promise<BaseResponseInterface<{ roles: RoleType[] }>> {
    return await HttpService.sendRequest('GET', '/role/dictionary');
  };

  public static async getOne(id: number): Promise<BaseResponseInterface<{ role: RoleType }>> {
    return await HttpService.sendRequest('GET', '/role/one/' + id);
  };

  public static async update(
    payload: RoleType,
  ): Promise<BaseResponseInterface<{ role: RoleType }>> {
    return await HttpService.sendRequest('PUT', '/role/update', {
      role: payload,
    });
  };
};
