import { Box, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';

import { AsteriskBlock } from '@blocks/asterisk';
import { FormFieldBlock } from '@blocks/form-field';

import { FieldMultiselectProps } from './field-multiselect.props';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FieldMultiselectBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  items,
  notError,
  required,
  counted,
}: FieldMultiselectProps) => {
  return (
    <FormFieldBlock error={error} notError={notError}>
      <FormControl variant="filled" fullWidth>
        <InputLabel id={name + '-label'}>{label}<AsteriskBlock required={required} counted={counted} /></InputLabel>
        <Select
          labelId={name + '-label'}
          id={name}
          multiple
          name={name}
          value={(value as unknown as string)}
          onChange={onChange}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {(selected as unknown as string[]).map((value) => (
                <Chip key={value} label={items.find((item) => item.value === value)?.label} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {items.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
            >
              <Checkbox checked={value.indexOf(String(item.value)) > -1} />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </FormFieldBlock>
  );
};

export { FieldMultiselectBlock };
