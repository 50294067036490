import { createAsyncThunk } from '@reduxjs/toolkit';

import { callNotifyAction } from '@stores/app/app.slice';

import { CollectionItemType } from '@type/collection/collection-item.type';
import { CollectionStateInterface } from '@type/collection/collection-state.interface';
import { CreateCollectionItemRequestInterface } from '@type/collection/create-collection-item-request.interface';
import { UpdateCollectionItemRequestInterface } from '@type/collection/update-collection-item-request.interface';
import { CollectionApi } from './collection.api';


export const getCollectionListAction = createAsyncThunk<
  { page: number, pages: number, list: CollectionItemType[] },
  { query: {key: string, page: number; parentKey?: string, parentId?: number}, searchQuery?: { [key: string]: any }},
  { state: { collection: CollectionStateInterface } }
>(
  '@@collection/get-list',
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await CollectionApi.getList({...payload.query, ...payload.searchQuery});
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка получения данных!' }));
      return rejectWithValue(response.errors);
    }

    return response.data;
  },
  {
    condition: (request, { getState }) => {
      const { collection } = getState();

      if (collection.collections[request.query.key].status === 'loading') {
        return false;
      }

      return true;
    }
  },
);

export const getCollectionOneAction = createAsyncThunk<
  CollectionItemType,
  { key: string, parentKey?: string, parentId?: number, id?: number},
  { state: { collection: CollectionStateInterface } }
>(
  '@@collection/get-one',
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await CollectionApi.getOne(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка получения данных!' }));
      return rejectWithValue(response.errors);
    }

    return response.data.one;
  },
  {
    condition: (request, { getState }) => {
      const { collection } = getState();

      if (collection.collections[request.key].status === 'loading') {
        return false;
      }

      return true;
    }
  },
);

export const createCollectionItemAction = createAsyncThunk<
  CollectionItemType,
  CreateCollectionItemRequestInterface,
  { state: { collection: CollectionStateInterface } }
>(
  '@@collection/create',
  async (payload: CreateCollectionItemRequestInterface, { rejectWithValue, dispatch }) => {
    const response = await CollectionApi.create(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data.one;
  },
  {
    condition: (request, { getState }) => {
      const { collection } = getState();

      if (collection.collections[request.key].status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const updateCollectionItemAction = createAsyncThunk<
  CollectionItemType,
  UpdateCollectionItemRequestInterface,
  { state: { collection: CollectionStateInterface } }
>(
  '@@collection/update',
  async (payload: UpdateCollectionItemRequestInterface, { rejectWithValue, dispatch }) => {
    const response = await CollectionApi.update(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено обновлены!' }));

    return response.data.one;
  },
  {
    condition: (request, { getState }) => {
      const { collection } = getState();

      if (collection.collections[request.key].status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const deleteCollectionOneAction = createAsyncThunk<
  CollectionItemType,
  { key: string, id: number},
  { state: { collection: CollectionStateInterface } }
>(
  '@@collection/delete',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(callNotifyAction({ type: 'info', message: 'loading' }));
    const response = await CollectionApi.deleteOne(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка удаления данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено удалены!' }));

    return response.data.one;
  },
  {
    condition: (request, { getState }) => {
      const { collection } = getState();

      if (collection.collections[request.key].status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);
