
import { Button } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import { ButtonFillProps } from './button-fill.props';


const ButtonFillBlock = ({ onClick }: ButtonFillProps) => {
  return (
    <Button
      color="primary"
      variant="outlined"
      startIcon={<ModeEditIcon />}
      onClick={onClick}
      size="large"
    >
      Заполнить
    </Button>
  );
};

export { ButtonFillBlock };
