
import { Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { ButtonClearProps } from './button-clear.props';


const ButtonClearBlock = ({ onClick, disabled }: ButtonClearProps) => {
  return (
      <Button
        color="primary"
        variant="outlined"
        startIcon={<ClearIcon />}
        onClick={onClick}
        size="large"
        disabled={disabled}
      >
        Сбросить
      </Button>
  );
};

export { ButtonClearBlock };
