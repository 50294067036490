import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DepartmentDetailFeature } from '@features/department-detail';
import { LayoutBaseFeature } from '@features/layout-base'
import { DepartmentUpdateFeature } from '@features/department-update';
import { setBreadcrumbs } from '@stores/app/app.slice';
import { getDepartmentOneAction } from '@stores/department/department.actions';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { ButtonBackBlock } from '@blocks/button-back';
import { CardDataBlock } from '@blocks/card-data';
import { ButtonEditBlock } from '@blocks/button-edit';
import { ButtonCancelBlock } from '@blocks/button-cancel';
import { TransitionOpacityBlock } from '@blocks/transition-opacity';

import { DepartmentDetailPageParams } from './department-detail.page-params';


const DepartmentDetailPage = () => {
  const dispatch = useAppDispatch();
  const department = useAppSelector((state) => state.department);
  const hasUpdateAccess = useAppSelector((state) => state.auth.user?.role.DEPARTMENT_UPDATE !== 'NEVER');
  const { id } = useParams<DepartmentDetailPageParams>();
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { title: 'Главная', link: '/' },
      { title: 'Подразделения', link: '/department-list' },
      { title: 'Подразделение №' + id, link: '' },
    ]));

    dispatch(getDepartmentOneAction(Number(id)));
  }, [dispatch, id]);

  return (
    <LayoutBaseFeature>
      <CardDataBlock
        title={'Подразделение №' + id}
        extra={
          <>
            {isEditing
              ? (
                <ButtonCancelBlock onClick={() => setIsEditing(false)} />
              )
              : hasUpdateAccess && (
                <ButtonEditBlock onClick={() => setIsEditing(true)} />
              )
            }
            {!isEditing && <ButtonBackBlock />}
          </>
        }
        loading={department.status === 'loading'}
      >
        {isEditing && (
          <TransitionOpacityBlock>
            <DepartmentUpdateFeature onDone={() => setIsEditing(false)} />
          </TransitionOpacityBlock>
        )}
        {department.current && !isEditing && (
          <TransitionOpacityBlock>
            <DepartmentDetailFeature />
          </TransitionOpacityBlock>
        )}
      </CardDataBlock>
    </LayoutBaseFeature>
  );
};

export { DepartmentDetailPage };
