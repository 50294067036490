import { FC } from 'react';
import cs from 'classnames';

import { FormFieldProps } from './form-field.props';
import styles from './form-field.module.scss';


const FormFieldBlock: FC<FormFieldProps> = ({ children, error, notError }) => {
  const formFieldClassname = cs(
    styles['form-field'],
    { [styles['form-field--not-error']]: notError },
  );

  return (
    <div className={formFieldClassname}>
      {children}
      {error && !notError && (
        <div className={styles['form-field__error']}>{error}</div>
      )}
    </div>
  )
}

export { FormFieldBlock };
