import { useNavigate } from "react-router-dom";

import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';

import { EntityActionsProps } from './entity-actions.props';


const EntityActionsBlock = ({
  title,
  linkCreate,
  linkList,
  canCreate,
  canRead,
}: EntityActionsProps) => {
  const navigate = useNavigate();

  return (
    <Card style={{ height: '100%' }}>
      <CardContent>
        <Typography variant="h5">{title}</Typography>
      </CardContent>
      <CardActions style={{ paddingTop: '0' }}>
        <div style={{ width: '100%' }}>
          {canRead && (
            <Button
              style={{ marginBottom: '5px' }}
              variant="outlined"
              fullWidth
              size="large"
              onClick={() => navigate(linkList)}
            >
              Список
            </Button>
          )}
          {canCreate && (
            <Button
              variant="outlined"
              fullWidth
              size="large"
              onClick={() => navigate(linkCreate)}
            >
              Добавить
            </Button>
          )}
        </div>
      </CardActions>
    </Card>
  );
};

export { EntityActionsBlock };
