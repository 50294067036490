import { createAsyncThunk } from '@reduxjs/toolkit';

import { callNotifyAction } from '@stores/app/app.slice';
import { CreateRoleRequestInterface } from '@type/role/create-role-request.interface';

import { RoleStateInterface } from '@type/role/role-state.interface';
import { RoleType } from '@type/role/role.type';

import { RoleApi } from './role.api';


export const getRoleListAction = createAsyncThunk<
  RoleType[],
  undefined,
  { state: { role: RoleStateInterface } }
>(
  '@@role/get-list',
  async (_, { rejectWithValue, dispatch }) => {
    const response = await RoleApi.getList();
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка получения данных!' }));
      return rejectWithValue(response.errors);
    }

    return response.data.roles;
  },
  {
    condition: (_, { getState }) => {
      const { role } = getState();

      if (role.status === 'loading') {
        return false;
      }

      return true;
    }
  },
);

export const getRoleDictionaryAction = createAsyncThunk<
  RoleType[],
  undefined,
  { state: { role: RoleStateInterface } }
>(
  '@@role/get-dictionary',
  async (_, { rejectWithValue, dispatch }) => {
    const response = await RoleApi.getDictionary();
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка получения данных!' }));
      return rejectWithValue(response.errors);
    }

    return response.data.roles;
  },
  {
    condition: (_, { getState }) => {
      const { role } = getState();

      if (role.status === 'loading') {
        return false;
      }

      return true;
    }
  },
);

export const getRoleOneAction = createAsyncThunk<
  RoleType,
  number,
  { state: { role: RoleStateInterface } }
>(
  '@@role/get-one',
  async (id: number, { rejectWithValue, dispatch }) => {
    const response = await RoleApi.getOne(id);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка получения данных!' }));
      return rejectWithValue(response.errors);
    }

    return response.data.role;
  },
  {
    condition: (_, { getState }) => {
      const { role } = getState();

      if (role.status === 'loading') {
        return false;
      }

      return true;
    }
  },
);

export const createRoleAction = createAsyncThunk<
  RoleType,
  CreateRoleRequestInterface,
  { state: { role: RoleStateInterface } }
>(
  '@@role/create',
  async (payload: CreateRoleRequestInterface, { rejectWithValue, dispatch }) => {
    const response = await RoleApi.create(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data.role;
  },
  {
    condition: (_, { getState }) => {
      const { role } = getState();

      if (role.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const updateRoleAction = createAsyncThunk<
  RoleType,
  RoleType,
  { state: { role: RoleStateInterface } }
>(
  '@@role/update',
  async (payload: RoleType, { rejectWithValue, dispatch }) => {
    const response = await RoleApi.update(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено обновлены!' }));

    return response.data.role;
  },
  {
    condition: (_, { getState }) => {
      const { role } = getState();

      if (role.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);
