import { TextField } from '@mui/material';

import { FormFieldBlock } from '@blocks/form-field';
import { ChangeFieldEventType } from '@type/shared/change-field-event.type';
import { AsteriskBlock } from '@blocks/asterisk';

import { FieldNumberProps } from './field-number.props';


const FieldNumberBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  required,
  counted,
}: FieldNumberProps) => {
  const onChangeHandler = (e: ChangeFieldEventType) => {
    e.target.value = (e.target.value as string).replace(/\D/g, '');

    onChange(e);
  }

  return (
    <FormFieldBlock error={error}>
      <TextField
        label={<>{label}<AsteriskBlock required={required} counted={counted} /></>}
        name={name}
        value={value}
        onChange={onChangeHandler}
        type="text"
        variant="filled"
        fullWidth
      />
    </FormFieldBlock>
  );
};

export { FieldNumberBlock };
