import { HttpService } from '@services/http.service';
import { CurrentUserType } from '@type/auth/current-user.type';
import { SigninRequestInterface } from '@type/auth/signin-request.interface';
import { BaseResponseInterface } from '@type/shared/base-response.interface';


export class AuthApi {
  public static async signin(
    payload: SigninRequestInterface,
  ): Promise<BaseResponseInterface<{ user: CurrentUserType }>> {
    return await HttpService.sendRequest('POST', '/auth/signin', {
      user: payload,
    });
  };

  public static async current(): Promise<BaseResponseInterface<{ user: CurrentUserType }>> {
    return await HttpService.sendRequest('GET', '/auth/current');
  };
};
