import { motion } from 'framer-motion';

import { TransitionOpacityProps } from './transition-opacity.props';


const TransitionOpacityBlock = ({ children }: TransitionOpacityProps) => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: .3 }}>
      {children}
    </motion.div>
  );
};

export { TransitionOpacityBlock };
