import { Card, CardActions, CardContent, Divider, LinearProgress, Typography } from '@mui/material';

import { TransitionHeightBlock } from '@blocks/transition-height';

import { CardDataProps } from './card-data.props';
import styles from './card-data.module.scss';


const CardDataBlock = ({
  children,
  title,
  extra,
  loading,
  noData,
  actions
}: CardDataProps) => {
  return (
    <Card className={styles['data-card']}>
      <CardContent className={styles['data-card__header']}>
        <div className={styles['data-card__title']}><Typography variant="h5">{title}</Typography></div>
        <div className={styles['data-card__extra']}>
          {extra}
        </div>
      </CardContent>
      
      <Divider />
      {loading && <div className={styles['data-card__loader']}><LinearProgress /></div>}
      {!loading && (
        <TransitionHeightBlock>
          {children}
        </TransitionHeightBlock>
      )}
      {noData && !loading && <div className={styles['data-card__no-data']}>Нет данных для отображения.</div>}

      {actions && !loading && (
        <>
          <Divider />
          <CardActions>
            {actions}
          </CardActions>
        </>
      )}
    </Card>
  );
};

export { CardDataBlock };
