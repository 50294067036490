import { SyntheticEvent, useState } from 'react';

import { Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { updateRoleAction } from '@stores/role/role.actions';
import { FieldTextBlock } from '@blocks/field-text';
import { FieldSelectBlock } from '@blocks/field-select';
import { ButtonSaveBlock } from '@blocks/button-save';
import { ButtonCancelBlock } from '@blocks/button-cancel';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { errorTranslate } from '@helpers/error-translate.helper';
import { ChangeFieldEventType } from '@type/shared/change-field-event.type';
import { RoleType } from '@type/role/role.type';

import { RoleUpdateProps } from './role-update.props';
import styles from './role-update.module.scss';
import { configDcs } from '@config/config-dcs';

const accessOptions = [
  { value: 'NEVER', label: 'Нет' },
  { value: 'ALL', label: 'Да' },
  { value: 'ONLY_MY', label: 'Только своих'},
  { value: 'ONLY_MY_DEPARTMENT', label: 'Только своего отделения' }
];

const RoleUpdateFeature = ({ onDone }: RoleUpdateProps) => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role);
  const [formValue, setFormValue] = useState<RoleType>(role.current!);
  const [formDirty, setFormDirty] = useState(false);

  const changeFormValue = (e: ChangeFieldEventType): void => {
    setFormDirty(true);
    setFormValue((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();

    const result = await dispatch(updateRoleAction(formValue));

    if (result.type === '@@role/update/fulfilled') {
      onDone();
    }
  }

  const setError = (fieldName: string) => {
    return role.errors && role.errors[fieldName] && errorTranslate(role.errors[fieldName][0]);
  }

  return (
    <div className={styles['role-update']}>
      <form onSubmit={(e: SyntheticEvent) => onSubmit(e)}>
        <div className="row justify-content-lg-center">
          <div className="col col-lg-6">
            <div className={styles['role-update__form']}>
              <FieldTextBlock
                name="name"
                label="Название"
                value={formValue.name}
                onChange={changeFormValue}
                error={setError('name')}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Divider />
            <TableContainer>
              <Table aria-label="department-detail">
                <TableHead style={{ display: 'none' }}>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">Подразделения</TableCell>
                    <TableCell>
                      <FieldSelectBlock
                        value={formValue.DEPARTMENT_CREATE}
                        name="DEPARTMENT_CREATE"
                        label="Создание"
                        onChange={changeFormValue}
                        items={[
                          { value: 'NEVER', label: 'Нет' },
                          { value: 'ALL', label: 'Да' },
                        ]}
                        notError
                      />
                    </TableCell>
                    <TableCell>
                      <FieldSelectBlock
                        value={formValue.DEPARTMENT_READ}
                        name="DEPARTMENT_READ"
                        label="Чтение"
                        onChange={changeFormValue}
                        items={[
                          { value: 'NEVER', label: 'Нет' },
                          { value: 'ALL', label: 'Да' },
                        ]}
                        notError
                      />
                    </TableCell>
                    <TableCell>
                      <FieldSelectBlock
                        value={formValue.DEPARTMENT_UPDATE}
                        name="DEPARTMENT_UPDATE"
                        label="Обновление"
                        onChange={changeFormValue}
                        items={[
                          { value: 'NEVER', label: 'Нет' },
                          { value: 'ALL', label: 'Да' },
                        ]}
                        notError
                      />
                    </TableCell>
                    <TableCell>
                      <FieldSelectBlock
                        value={formValue.DEPARTMENT_DELETE}
                        name="DEPARTMENT_DELETE"
                        label="Удаление"
                        onChange={changeFormValue}
                        items={[
                          { value: 'NEVER', label: 'Нет' },
                          { value: 'ALL', label: 'Да' },
                        ]}
                        notError
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Роли</TableCell>
                    <TableCell>
                      <FieldSelectBlock
                        value={formValue.ROLE_CREATE}
                        name="ROLE_CREATE"
                        label="Создание"
                        onChange={changeFormValue}
                        items={[
                          { value: 'NEVER', label: 'Нет' },
                          { value: 'ALL', label: 'Да' },
                        ]}
                        notError
                      />
                    </TableCell>
                    <TableCell>
                      <FieldSelectBlock
                        value={formValue.ROLE_READ}
                        name="ROLE_READ"
                        label="Чтение"
                        onChange={changeFormValue}
                        items={[
                          { value: 'NEVER', label: 'Нет' },
                          { value: 'ALL', label: 'Да' },
                        ]}
                        notError
                      />
                    </TableCell>
                    <TableCell>
                      <FieldSelectBlock
                        value={formValue.ROLE_UPDATE}
                        name="ROLE_UPDATE"
                        label="Обновление"
                        onChange={changeFormValue}
                        items={[
                          { value: 'NEVER', label: 'Нет' },
                          { value: 'ALL', label: 'Да' },
                        ]}
                        notError
                      />
                    </TableCell>
                    <TableCell>
                      <FieldSelectBlock
                        value={formValue.ROLE_DELETE}
                        name="ROLE_DELETE"
                        label="Удаление"
                        onChange={changeFormValue}
                        items={[
                          { value: 'NEVER', label: 'Нет' },
                          { value: 'ALL', label: 'Да' },
                        ]}
                        notError
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Пользователи</TableCell>
                    <TableCell>
                      <FieldSelectBlock
                        value={formValue.USER_CREATE}
                        name="USER_CREATE"
                        label="Создание"
                        onChange={changeFormValue}
                        items={[
                          { value: 'NEVER', label: 'Нет' },
                          { value: 'ALL', label: 'Да' },
                        ]}
                        notError
                      />
                    </TableCell>
                    <TableCell>
                      <FieldSelectBlock
                        value={formValue.USER_READ}
                        name="USER_READ"
                        label="Чтение"
                        onChange={changeFormValue}
                        items={[
                          { value: 'NEVER', label: 'Нет' },
                          { value: 'ALL', label: 'Да' },
                        ]}
                        notError
                      />
                    </TableCell>
                    <TableCell>
                      <FieldSelectBlock
                        value={formValue.USER_UPDATE}
                        name="USER_UPDATE"
                        label="Обновление"
                        onChange={changeFormValue}
                        items={[
                          { value: 'NEVER', label: 'Нет' },
                          { value: 'ALL', label: 'Да' },
                        ]}
                        notError
                      />
                    </TableCell>
                    <TableCell>
                      <FieldSelectBlock
                        value={formValue.USER_DELETE}
                        name="USER_DELETE"
                        label="Удаление"
                        onChange={changeFormValue}
                        items={[
                          { value: 'NEVER', label: 'Нет' },
                          { value: 'ALL', label: 'Да' },
                        ]}
                        notError
                      />
                    </TableCell>
                  </TableRow>

                  {configDcs.entities.map((entity) => (
                    <TableRow key={entity.key}>
                      <TableCell component="th" scope="row">{entity.label.plural}</TableCell>
                      <TableCell>
                        <FieldSelectBlock
                          value={formValue[entity.key + '_CREATE']}
                          name={entity.key + '_CREATE'}
                          label="Создание"
                          onChange={changeFormValue}
                          items={[
                            { value: 'NEVER', label: 'Нет' },
                            { value: 'ALL', label: 'Да' },
                          ]}
                          notError
                        />
                      </TableCell>
                      <TableCell>
                        <FieldSelectBlock
                          value={formValue[entity.key + '_READ']}
                          name={entity.key + '_READ'}
                          label="Чтение"
                          onChange={changeFormValue}
                          items={accessOptions}
                          notError
                        />
                      </TableCell>
                      <TableCell>
                        <FieldSelectBlock
                          value={formValue[entity.key + '_UPDATE']}
                          name={entity.key + '_UPDATE'}
                          label="Обновление"
                          onChange={changeFormValue}
                          items={accessOptions}
                          notError
                        />
                      </TableCell>
                      <TableCell>
                        <FieldSelectBlock
                          value={formValue[entity.key + '_DELETE']}
                          name={entity.key + '_DELETE'}
                          label="Удаление"
                          onChange={changeFormValue}
                          items={accessOptions}
                          notError
                        />
                      </TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <div className="row justify-content-lg-center">
          <div className="col col-lg-6">
            <div className={styles['role-update__buttons']}>
              <ButtonSaveBlock
                loading={role.status === 'submitting'}
                onClick={onSubmit}
                disabled={!formDirty}
              />
              <ButtonCancelBlock onClick={() => onDone()} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export { RoleUpdateFeature };
