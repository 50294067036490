import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { dateTranslate } from '@helpers/date-translate.helper';
import { buildColumnValue } from '@helpers/build-column-value.helper';

import { EntityTableProps } from './entity-table.props';


const EntityTableComponent = ({ entity, collection }: EntityTableProps) => {
  return (
    <div style={{overflowX: 'auto', overflowY: 'hidden', whiteSpace: 'nowrap'}}>
      <TableContainer style={{width: '100%'}}>
        <Table aria-label={entity.key + '-detail'}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>

              {entity.columns.map((column) => {
                if (!column.display.one) {
                  return null;
                }

                return <TableCell key={column.key}>{column.label}</TableCell>;
              })}
              
              <TableCell>Создал</TableCell>
              <TableCell>Дата создания</TableCell>
              <TableCell>Обновил</TableCell>
              <TableCell>Дата обновления</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              {collection.current && (
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">{collection.current?.id}</TableCell>

                  {entity.columns.map((column) => {
                    if (!column.display.one) {
                      return null;
                    }

                    const value = collection.current ? collection.current[column.key] : ''
                    return (
                      <TableCell key={column.key}><>{buildColumnValue(column, value)}</></TableCell>
                    );
                  })}
                  
                  <TableCell>{collection.current?.creator.name}</TableCell>
                  <TableCell>{dateTranslate(collection.current?.createdAt)}</TableCell>
                  <TableCell>{collection.current?.updater.name}</TableCell>
                  <TableCell>{dateTranslate(collection.current?.updatedAt)}</TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export { EntityTableComponent };