import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { ButtonBackProps } from './button-back.props';


const ButtonBackBlock = ({ onClick }: ButtonBackProps) => {
  const navigate = useNavigate();

  return (
    <Button
      variant='outlined'
      startIcon={<KeyboardBackspaceIcon />}
      onClick={!!onClick ? () => onClick() : () => navigate(-1)}
      size="large"
    >
      Назад
    </Button>
  );
};

export { ButtonBackBlock };
