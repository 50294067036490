
import { Button } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import { ButtonEditProps } from './button-edit.props';


const ButtonEditBlock = ({ onClick }: ButtonEditProps) => {
  return (
    <Button
      onClick={onClick}
      size="large"
      startIcon={<ModeEditIcon />}
      variant="outlined"
    >
      Редактировать
    </Button>
  );
};

export { ButtonEditBlock };
