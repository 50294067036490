import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { AnonGuardHoc } from '@hocs/anon-guard';
import { AuthGuardHoc } from '@hocs/auth-guard';
import { pathsBuild } from '@helpers/paths-build.helper';
import { HomePage } from '@pages/home';
import { DepartmentListPage } from '@pages/department-list';
import { SigninPage } from '@pages/signin';
import { NotFoundPage } from '@pages/not-found';
import { DepartmentCreatePage } from '@pages/department-create';
import { DepartmentDetailPage } from '@pages/department-detail';
import { RoleListPage } from '@pages/role-list';
import { RoleCreatePage } from '@pages/role-create';
import { RoleDetailPage } from '@pages/role-detail';
import { UserListPage } from '@pages/user-list';
import { UserCreatePage } from '@pages/user-create';
import { UserDetailPage } from '@pages/user-detail';
import { EntityPage } from '@pages/entity';


const router = createBrowserRouter([
  { path: '/', element: <AuthGuardHoc><HomePage /></AuthGuardHoc> },
  { path: '/department-list', element: <AuthGuardHoc><DepartmentListPage /></AuthGuardHoc> },
  { path: '/department-list/department-create', element: <AuthGuardHoc><DepartmentCreatePage /></AuthGuardHoc> },
  { path: '/department-list/:id', element: <AuthGuardHoc><DepartmentDetailPage /></AuthGuardHoc> },
  { path: '/role-list', element: <AuthGuardHoc><RoleListPage /></AuthGuardHoc> },
  { path: '/role-list/role-create', element: <AuthGuardHoc><RoleCreatePage /></AuthGuardHoc> },
  { path: '/role-list/:id', element: <AuthGuardHoc><RoleDetailPage /></AuthGuardHoc> },
  { path: '/user-list', element: <AuthGuardHoc><UserListPage /></AuthGuardHoc> },
  { path: '/user-list/user-create', element: <AuthGuardHoc><UserCreatePage /></AuthGuardHoc> },
  { path: '/user-list/:id', element: <AuthGuardHoc><UserDetailPage /></AuthGuardHoc> },
  { path: '/signin', element: <AnonGuardHoc><SigninPage /></AnonGuardHoc> },

  ...pathsBuild().map((path) => ({
    path: `${path}`,
    element: <AuthGuardHoc><EntityPage /></AuthGuardHoc>
  })),

  { path: '*', element: <NotFoundPage /> },
]);

const RouterOutletFeature = (): JSX.Element => {
  return (
    <RouterProvider router={router} />
  );
};

export { RouterOutletFeature };
