import { EntityType } from '@type/config/entity.type';

export const kolposkopiyaCherezGodEntity: EntityType = {
  key: 'kolposkopiya_cherez_god',
  label: {
    singular: 'Кольпоскопия через год',
    plural: 'Кольпоскопия через год',
    genitive: 'Кольпоскопия через год',
  },
  type: 'one',
  parent: 'card',
  columns: [
    {
      label: 'Дата приема',
      key: 'data-priema',
      type: 'date',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      required: true,
    },
    {
      label: 'Тип зоны трансформации на кольпоскопии',
      key: 'tip-zony-transformacii-na-kolposkopii',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: ['Тип 1', 'Тип 2', 'Тип 3'],
    },
    {
      label: 'Результат осмотра зоны трансформации',
      key: 'rezultat-osmotra-zony-transformacii',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: ['Норма', 'Патологические изменения'],
    },
    {
      label: 'Степень атрофии (уксусная проба)',
      key: 'stepen-atrofii-uksusnaya-proba',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: ['Нет атрофии', 'Легкая', 'Выраженная'],
    },
    {
      label: 'Децидиоз',
      key: 'decidioz',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: ['Нет', 'Полиповидный', 'Опухолевидный'],
    },
    {
      label: 'Открытые железы',
      key: 'otkrytye-zhelezy',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: ['Нет', 'Нормальные', 'С ороговением'],
    },
    {
      label: 'Ретенционные кисты (закрытые железы)',
      key: 'retencionnye-kisty-zakrytye-zhelezy',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: ['Нет', 'Единичные', 'Множественные'],
    },
    {
      label: 'Видимые сосуды',
      key: 'vidimye-sosudy',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: ['Нет', 'Типичные', 'Атипичные'],
    },
    {
      label: 'Лейкоплакия',
      key: 'lejkoplakiya',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Кератоз',
      key: 'keratoz',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Мозаика',
      key: 'mozaika',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: ['Нет', 'Нежная', 'Грубая'],
    },
    {
      label: 'Пунктация',
      key: 'punktaciya',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: ['Нет', 'Нежная', 'Грубая'],
    },
    {
      label: 'Ацето-белый эпителий',
      key: 'aceto-belyj-ehpitelij',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: ['Нет', 'Нежный', 'Грубый'],
    },
    {
      label: 'Внутри поражения АБЭ видна внутренняя граница',
      key: 'vidna-vnutrennyaya-granica',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Признак гребня(складки)',
      key: 'priznak-grebnya-skladki',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Эктопия',
      key: 'ehktopiya',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: ['Нет', 'Небольшая', 'Большая'],
    },
    {
      label: 'Края поражения',
      key: 'kraya-porazheniya',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: ['Нет', 'Четкие', 'Нечеткие'],
    },
    {
      label: 'Йод-негативная зона',
      key: 'jod-negativnaya-zona',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: ['Нет', 'Резкая', 'Нерезкая'],
    },
    {
      label: 'Эндометриоз',
      key: 'ehndometrioz',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: ['Нет', 'Точечный', 'Линейный'],
    },
    {
      label: 'Выполнена биопсия',
      key: 'vypolnena-biopsiya',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Диагноз кольпоскопический',
      key: 'diagnoz-kolposkopicheskij',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: [
        'Нормальная кольпоскопическая картина',
        'Аномальная кольпоскопическая картина 1 степени',
        'Аномальная кольпоскопическая картина 2 степени',
        'Аномальная кольпоскопическая картина неспецифическая',
      ]
    },
    {
      label: 'Комментарии специалиста',
      key: 'kommentarii-specialista',
      type: 'long-text',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Результат гистологического исследования ЦСМП',
      key: 'gistologicheskogoe-issledovaniya-csmp',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: false,
      variants: [
        'Норма',
        'Неопределенные воспалительные изменения',
        'CIN I',
        'CIN II',
        'CIN III',
        'Cr in situ',
        'Cr инвазивный',
      ],
      visibleRule: {
        fieldKey: 'vypolnena-biopsiya',
        equal: true,
      },
    },
    {
      label: 'Результат гистологического исследования ОКБ',
      key: 'gistologicheskogoe-issledovaniya-okb',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: false,
      variants: [
        'Норма',
        'Неопределенные воспалительные изменения',
        'CIN I',
        'CIN II',
        'CIN III',
        'Cr in situ',
        'Cr инвазивный',
      ],
      visibleRule: {
        fieldKey: 'vypolnena-biopsiya',
        equal: true,
      },
    },
  ],
}
