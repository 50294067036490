import { FieldPhoneProps } from './field-phone.props';
import { FilledInput, FormControl, InputAdornment, InputLabel } from '@mui/material';

import { FormFieldBlock } from '@blocks/form-field';
import { ChangeFieldEventType } from '@type/shared/change-field-event.type';
import { AsteriskBlock } from '@blocks/asterisk';

import { useState } from 'react';


const FieldPhoneBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  required,
  counted,
}: FieldPhoneProps) => {
  const handleChange = (e: ChangeFieldEventType) => {
    if ((e.target.value as string).length > 10) {
      return;
    }
    
    e.target.value = (e.target.value as string).replace(/\D/g, '');

    setModValue(e.target.value);

    if (e.target.value.indexOf('+7') === -1 && e.target.value) {
      e.target.value = '+7' + e.target.value;
    }

    onChange(e);
  };

  const [modValue, setModValue] = useState(value ? value.slice(2) : '');
  
  return (
    <FormFieldBlock error={error}>
      <FormControl fullWidth variant="filled">
        <InputLabel htmlFor={name}>{label}<AsteriskBlock required={required} counted={counted} /></InputLabel>
        <FilledInput
          id={name}
          name={name}
          value={modValue}
          onChange={handleChange}
          type="text"
          startAdornment={<InputAdornment position="start">+7</InputAdornment>}
        />
      </FormControl>
    </FormFieldBlock>
  );
};

export { FieldPhoneBlock };
