import { Link, useNavigate } from 'react-router-dom';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { dateTranslate } from '@helpers/date-translate.helper';
import { CardDataBlock } from '@blocks/card-data';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { getRoleListAction } from '@stores/role/role.actions';
import { ButtonCreateBlock } from '@blocks/button-create';


const RoleListFeature = () => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role);
  const navigate = useNavigate();
  const hasAccessCreate = useAppSelector((state) => state.auth.user?.role.ROLE_CREATE !== 'NEVER');

  useEffect(() => {
    dispatch(getRoleListAction());
  }, [dispatch])

  return (
    <CardDataBlock
      title="Роли"
      extra={
        <>
          {hasAccessCreate && <ButtonCreateBlock onClick={() => navigate('/role-list/role-create')} />}
        </>
      }
      loading={role.status === 'loading'}
      noData={role.list.length === 0}
    >
      <TableContainer>
        <Table aria-label="roles">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Название</TableCell>
              <TableCell>Дата создания</TableCell>
              <TableCell>Дата обновления</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {role.list.map((item) => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={item.id}
              >
                <TableCell component="th" scope="row">
                  {item.id}
                </TableCell>
                <TableCell><Link to={'/role-list/' + item.id}>{item.name}</Link></TableCell>
                <TableCell>{dateTranslate(item.createdAt)}</TableCell>
                <TableCell>{dateTranslate(item.updatedAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CardDataBlock>
  );
};

export { RoleListFeature };
