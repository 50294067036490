import { createSlice } from '@reduxjs/toolkit';

import { BackendErrorsType } from '@type/shared/backend-errors.type';
import { CollectionStateInterface } from '@type/collection/collection-state.interface';
import { configDcs } from '@config/config-dcs';
import { createCollectionItemAction, deleteCollectionOneAction, getCollectionListAction, getCollectionOneAction, updateCollectionItemAction } from './collection.actions';


const initialState: CollectionStateInterface = {
  collections: {},
}

configDcs.entities.forEach((entity) => {
  initialState.collections[entity.key] = {
    list: [],
    current: null,
    status: 'idle',
    errors: null,
    page: null,
    pages: null,
    searchQuery: {},
  }
})

const collectionSlice = createSlice({
  name: '@@collection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCollectionListAction.pending, (state, action) => {
        const key = action.meta.arg.query.key;

        state.collections[key].status = 'loading';
        state.collections[key].errors = null;
        state.collections[key].list = [];

        if (action.meta.arg.searchQuery) {
          state.collections[key].searchQuery = action.meta.arg.searchQuery;
        }
      })
      .addCase(getCollectionListAction.fulfilled, (state, action) => {
        const key = action.meta.arg.query.key;

        state.collections[key].status = 'idle';
        state.collections[key].list = action.payload.list;
        state.collections[key].page = action.payload.page;
        state.collections[key].pages = action.payload.pages;
      })
      .addCase(getCollectionListAction.rejected, (state, action) => {
        const key = action.meta.arg.query.key;
        state.collections[key].status = 'idle';
      })

      .addCase(getCollectionOneAction.pending, (state, action) => {
        const key = action.meta.arg.key;

        state.collections[key].status = 'loading';
        state.collections[key].errors = null;
        state.collections[key].current = null;
      })
      .addCase(getCollectionOneAction.fulfilled, (state, action) => {
        const key = action.meta.arg.key;

        state.collections[key].status = 'idle';
        state.collections[key].current = action.payload;
      })
      .addCase(getCollectionOneAction.rejected, (state, action) => {
        const key = action.meta.arg.key;

        state.collections[key].status = 'idle';
      })

      .addCase(createCollectionItemAction.pending, (state, action) => {
        const key = action.meta.arg.key;

        state.collections[key].status = 'submitting';
        state.collections[key].errors = null;
      })
      .addCase(createCollectionItemAction.fulfilled, (state, action) => {
        const key = action.meta.arg.key;

        state.collections[key].status = 'idle';
        state.collections[key].current = action.payload;
      })
      .addCase(createCollectionItemAction.rejected, (state, action) => {
        const key = action.meta.arg.key;

        state.collections[key].status = 'error';
        state.collections[key].errors = action.payload as BackendErrorsType;
      })

      .addCase(updateCollectionItemAction.pending, (state, action) => {
        const key = action.meta.arg.key;

        state.collections[key].status = 'submitting';
        state.collections[key].errors = null;
      })
      .addCase(updateCollectionItemAction.fulfilled, (state, action) => {
        const key = action.meta.arg.key;

        state.collections[key].status = 'idle';
        state.collections[key].current = action.payload;
      })
      .addCase(updateCollectionItemAction.rejected, (state, action) => {
        const key = action.meta.arg.key;

        state.collections[key].status = 'error';
        state.collections[key].errors = action.payload as BackendErrorsType;
      })
      
      .addCase(deleteCollectionOneAction.pending, (state, action) => {
        const key = action.meta.arg.key;

        state.collections[key].status = 'submitting';
        state.collections[key].errors = null;
      })
      .addCase(deleteCollectionOneAction.fulfilled, (state, action) => {
        const key = action.meta.arg.key;

        state.collections[key].status = 'idle';
        state.collections[key].current = null;
      })
      .addCase(deleteCollectionOneAction.rejected, (state, action) => {
        const key = action.meta.arg.key;

        state.collections[key].status = 'idle';
      });
  },
});

export default collectionSlice.reducer;
