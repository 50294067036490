import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { AsteriskBlock } from '@blocks/asterisk';
import { FormFieldBlock } from '@blocks/form-field';

import { FieldSelectProps } from './field-select.props';


const FieldSelectBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  items,
  notError,
  required,
  counted,
}: FieldSelectProps) => {
  return (
    <FormFieldBlock error={error} notError={notError}>
      <FormControl variant="filled" fullWidth>
        <InputLabel id={name + '-label'}>{label}<AsteriskBlock required={required} counted={counted} /></InputLabel>
        <Select
          labelId={name + '-label'}
          id={name}
          name={name}
          value={value}
          onChange={onChange}
        >
          {items.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </FormFieldBlock>
  );
};

export { FieldSelectBlock };
