import { HttpService } from '@services/http.service';
import { BaseResponseInterface } from '@type/shared/base-response.interface';
import { CollectionItemType } from '@type/collection/collection-item.type';
import { CreateCollectionItemRequestInterface } from '@type/collection/create-collection-item-request.interface';
import { UpdateCollectionItemRequestInterface } from '@type/collection/update-collection-item-request.interface';


export class CollectionApi {
  public static async create(
    payload: CreateCollectionItemRequestInterface,
  ): Promise<BaseResponseInterface<{ one: CollectionItemType }>> {
    return await HttpService.sendRequest('POST', '/collection/create', {
      one: payload,
    });
  };

  public static async getList(payload: { key: string, parentKey?: string, parentId?: number }): Promise<BaseResponseInterface<{ list: CollectionItemType[]; page: number; pages: number }>> {
    return await HttpService.sendRequest('GET', '/collection/list', payload);
  };

  public static async getOne(payload: { key: string, parentKey?: string, parentId?: number, id?: number }): Promise<BaseResponseInterface<{ one: CollectionItemType }>> {
    return await HttpService.sendRequest('GET', '/collection/one', payload);
  };

  public static async deleteOne(payload: { key: string, id: number }): Promise<BaseResponseInterface<{ one: CollectionItemType }>> {
    return await HttpService.sendRequest('DELETE', '/collection/delete', payload);
  };

  public static async update(
    payload: UpdateCollectionItemRequestInterface,
  ): Promise<BaseResponseInterface<{ one: CollectionItemType }>> {
    return await HttpService.sendRequest('PUT', '/collection/update', {
      one: payload,
    });
  };
};
