import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { AsteriskBlock } from '@blocks/asterisk';
import { FormFieldBlock } from '@blocks/form-field';

import { FieldBooleanProps } from './field-boolean.props';


const FieldBooleanBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  notError,
  required,
  counted,
}: FieldBooleanProps) => {
  return (
    <FormFieldBlock error={error} notError={notError}>
      <FormControl variant="filled" fullWidth>
        <InputLabel id={name + '-label'}>{label}<AsteriskBlock required={required} counted={counted} /></InputLabel>
        <Select
          labelId={name + '-label'}
          id={name}
          name={name}
          value={value === true ? '1' : value === false ? '0' : ''}
          onChange={(e) => {
            onChange({ target:  { name, value: e.target.value === '1' ? true : false } })
          }}
        >
          <MenuItem value="0">Нет</MenuItem>
          <MenuItem value="1">Да</MenuItem>
        </Select>
      </FormControl>
    </FormFieldBlock>
  );
};

export { FieldBooleanBlock };
