import { useEffect } from 'react';

import { CardDataBlock } from '@blocks/card-data';
import { LayoutBaseFeature } from '@features/layout-base'
import { useAppDispatch } from '@hooks/redux.hooks';
import { setBreadcrumbs } from '@stores/app/app.slice';
import { DepartmentCreateFeature } from '@features/department-create';
import { ButtonBackBlock } from '@blocks/button-back';


const DepartmentCreatePage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { title: 'Главная', link: '/' },
      { title: 'Подразделения', link: '/department-list' },
      { title: 'Добавить подразделение', link: '' },
    ]))
  }, [dispatch]);

  return (
    <LayoutBaseFeature>
      <div className="row">
        <div className="col-sm-12">
          <CardDataBlock
            title="Добавить подразделение"
            extra={<ButtonBackBlock />}
          >
            <div className="row justify-content-lg-center">
              <div className="col col-lg-6">
                <DepartmentCreateFeature />
              </div>
            </div>
          </CardDataBlock>
        </div>
      </div>
    </LayoutBaseFeature>
  );
};

export { DepartmentCreatePage };
