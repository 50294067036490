import { createSlice } from '@reduxjs/toolkit';

import { BackendErrorsType } from '@type/shared/backend-errors.type';
import { DepartmentStateInterface } from '@type/department/department-state.interface';

import { createDepartmentAction, getDepartmentDictionaryAction, getDepartmentListAction, getDepartmentOneAction, updateDepartmentAction } from './department.actions';


const initialState: DepartmentStateInterface = {
  list: [],
  dictionary: [],
  current: null,
  status: 'idle',
  errors: null,
}

const departmentSlice = createSlice({
  name: '@@department',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDepartmentListAction.pending, (state) => {
        state.status = 'loading';
        state.errors = null;
        state.list = [];
      })
      .addCase(getDepartmentListAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload;
      })
      .addCase(getDepartmentListAction.rejected, (state) => {
        state.status = 'idle';
      })

      .addCase(getDepartmentDictionaryAction.pending, (state) => {
        state.status = 'loading';
        state.errors = null;
        state.list = [];
      })
      .addCase(getDepartmentDictionaryAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.dictionary = action.payload;
      })
      .addCase(getDepartmentDictionaryAction.rejected, (state) => {
        state.status = 'idle';
      })

      .addCase(getDepartmentOneAction.pending, (state) => {
        state.status = 'loading';
        state.errors = null;
        state.current = null;
      })
      .addCase(getDepartmentOneAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.current = action.payload;
      })
      .addCase(getDepartmentOneAction.rejected, (state) => {
        state.status = 'idle';
      })

      .addCase(createDepartmentAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(createDepartmentAction.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(createDepartmentAction.rejected, (state, action) => {
        state.status = 'error';
        state.errors = action.payload as BackendErrorsType;
      })

      .addCase(updateDepartmentAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(updateDepartmentAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.current = action.payload
      })
      .addCase(updateDepartmentAction.rejected, (state, action) => {
        state.status = 'error';
        state.errors = action.payload as BackendErrorsType;
      });
  },
});

export default departmentSlice.reducer;

// export const {
//   // signinAction,
//   // signoutAction,
// } = authSlice.actions;
