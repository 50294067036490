import { motion } from 'framer-motion';

import { TransitionHeightProps } from './transition-height.props';


const TransitionHeightBlock = ({ children }: TransitionHeightProps) => {
  return (
    <motion.div
      style={{ overflow: 'hidden' }}
      initial={{ opacity: 0, height: '100px' }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: .1 }}
    >
      {children}
    </motion.div>
  );
};

export { TransitionHeightBlock };
