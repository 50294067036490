import { EntityWidgetsFeature } from '@features/entity-widgets';
import { LayoutBaseFeature } from '@features/layout-base';
import { useAppDispatch } from '@hooks/redux.hooks';
import { setBreadcrumbs } from '@stores/app/app.slice';
import { useEffect } from 'react';


const HomePage = () => {
  const dispatch = useAppDispatch();


  useEffect(() => {
    dispatch(setBreadcrumbs([
      { title: 'Главная', link: '/' }
    ]));
  }, [dispatch]);

  return (
    <LayoutBaseFeature>
      <EntityWidgetsFeature />
    </LayoutBaseFeature>
  );
};

export { HomePage };
