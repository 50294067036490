import { useEffect, useState } from 'react';

import { Typography } from '@mui/material';

import { ButtonEditBlock } from '@blocks/button-edit';
import { ButtonCancelBlock } from '@blocks/button-cancel';
import { CardDataBlock } from '@blocks/card-data';
import { ButtonFillBlock } from '@blocks/button-fill';
import { TransitionOpacityBlock } from '@blocks/transition-opacity';
import { ButtonRemoveBlock } from '@blocks/button-remove';
import { calculateProgress } from '@helpers/calculate-progress.helper';
import { prepareFormValue } from '@helpers/prepare-form-value.helper';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { createCollectionItemAction, deleteCollectionOneAction, getCollectionOneAction, updateCollectionItemAction } from '@stores/collection/collection.actions';

import { EntityOneProps } from './entity-one.props';
import { EntityFormComponent } from './components/entity-form';
import { EntityTableComponent } from './components/entity-table';
import { useLocation, useNavigate } from 'react-router-dom';


const EntityOneFeature = ({ entity, id, parentId, isChildren }: EntityOneProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [collection, loading, submitting] = useAppSelector((state) => [
    state.collection.collections[entity.key],
    state.collection.collections[entity.key].status === 'loading',
    state.collection.collections[entity.key].status === 'submitting',
  ]);
  const hasAccessUpdate = useAppSelector((state) => {
    if (state.auth.user!.role[`${entity.key}_UPDATE`] === 'NEVER') {
      return false;
    }

    if (state.auth.user!.role[`${entity.key}_UPDATE`] === 'ONLY_MY') {
      return collection.current?.creator.id === state.auth.user?.id;
    }

    if (state.auth.user!.role[`${entity.key}_UPDATE`] === 'ONLY_MY_DEPARTMENT') {
      return collection.current?.department.id === state.auth.user?.department.id;
    }

    if (state.auth.user!.role[`${entity.key}_UPDATE`] === 'ALL') {
      return true;
    }
  });

  const hasAccessCreate = useAppSelector((state) => {
    if (state.auth.user!.role[`${entity.key}_CREATE`] === 'NEVER') {
      return false;
    }

    if (state.auth.user!.role[`${entity.key}_CREATE`] === 'ALL') {
      return true;
    }
  });

  const hasAccessDelete = useAppSelector((state) => {
    if (state.auth.user!.role[`${entity.key}_DELETE`] === 'NEVER') {
      return false;
    }

    if (state.auth.user!.role[`${entity.key}_DELETE`] === 'ONLY_MY') {
      return collection.current?.creator.id === state.auth.user?.id;
    }

    if (state.auth.user!.role[`${entity.key}_DELETE`] === 'ONLY_MY_DEPARTMENT') {
      return collection.current?.department.id === state.auth.user?.department.id;
    }

    if (state.auth.user!.role[`${entity.key}_DELETE`] === 'ALL') {
      return true;
    }
  });

  const [isEditing, setIsEditing] = useState(false);

  const onSubmit = async (formValue: { [key: string]: any }) => {
    if (!collection.current) {
      const result = await dispatch(createCollectionItemAction({
        key: entity.key,
        parentKey: entity.parent,
        parentId,
        ...prepareFormValue(formValue),
      }));

      if (result.type === '@@collection/create/fulfilled') {
        setIsEditing(false);
      }
    } else {
      const result = await dispatch(updateCollectionItemAction({
        id: collection.current.id,
        key: entity.key,
        ...prepareFormValue(formValue),
      }));

      if (result.type === '@@collection/update/fulfilled') {
        setIsEditing(false);
      }
    }
  }

  const onDelete = async () => {
    const result = await dispatch(deleteCollectionOneAction({ key: entity.key, id: collection.current!.id }))

    if (result.type === '@@collection/delete/fulfilled' && entity.type === 'list') {
      const newPathArr = location.pathname.split('/');
      const newPath = newPathArr.filter((_, i) => i < newPathArr.length - 1).join('/');
      navigate(newPath);
    }
  }

  useEffect(() => {
    if (isChildren) {
      dispatch(getCollectionOneAction({ key: entity.key, parentId: Number(parentId) }))
    } else {
      dispatch(getCollectionOneAction({ key: entity.key, id: Number(id) }))
    }

    setIsEditing(false);
  }, [dispatch, entity.key, parentId, id, isChildren]);

  const progress = calculateProgress(collection.current, entity.columns);

  return (
    <CardDataBlock
      title={entity.label.singular}
      extra={
        <>
          {!isEditing && <Typography variant="subtitle2" gutterBottom mb="0">Заполнено: {progress[0]}/{progress[1]}</Typography>}
          {isEditing && <ButtonCancelBlock onClick={() => setIsEditing(false)}/>}
          {(!isEditing && collection.current && hasAccessUpdate) && <ButtonEditBlock onClick={() => setIsEditing(true)}/>}
          {(!isEditing && collection.current && hasAccessDelete) && <ButtonRemoveBlock onClick={onDelete}/>}
          {(!isEditing && !collection.current && hasAccessCreate) && <ButtonFillBlock onClick={() => setIsEditing(true)}/>}
        </>
      }
      loading={loading}
      noData={!collection.current && !isEditing}
    >
      {isEditing && (
        <TransitionOpacityBlock>
          <EntityFormComponent
            entity={entity}
            collection={collection}
            submitting={submitting}
            onSubmitHandler={onSubmit}
            onCancelHandler={() => setIsEditing(false)}
          />
        </TransitionOpacityBlock>
      )}
      {!isEditing && (
        <TransitionOpacityBlock>
          <EntityTableComponent
            entity={entity}
            collection={collection}
          />
        </TransitionOpacityBlock>
      )}
    </CardDataBlock>
  );
}

export { EntityOneFeature };
