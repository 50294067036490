import { EntityType } from '@type/config/entity.type';

export const mutaciiCherezGodEntity: EntityType = {
  key: 'mutacii_cherez_god',
  label: {
    singular: 'Мутации через год',
    plural: 'Мутации через год',
    genitive: 'Мутации через год',
  },
  type: 'one',
  parent: 'card',
  columns: [
    {
      label: 'PI3KCA 1',
      key: 'pi3kca-1',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'PI3KCA 2',
      key: 'pi3kca-2',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'MAPK1',
      key: 'mapk1',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'ERBB2',
      key: 'erbb2',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'FBXW7',
      key: 'fbxw7',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'AKT1',
      key: 'akt1',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'KRAS',
      key: 'kras',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'KLF5',
      key: 'klf5',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'EP300',
      key: 'ep300',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'PLAU',
      key: 'plau',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'PLAUR',
      key: 'plaur',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'TP53',
      key: 'tp53',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'BRCA2',
      key: 'brca2',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Комментарий',
      key: 'kommentarij',
      type: 'long-text',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: false,
    },
  ],
}
