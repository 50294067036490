import { Link, useNavigate } from 'react-router-dom';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { dateTranslate } from '@helpers/date-translate.helper';
import { CardDataBlock } from '@blocks/card-data';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { getUserListAction } from '@stores/user/user.actions';
import { ButtonCreateBlock } from '@blocks/button-create';


const UserListFeature = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const hasAccessCreate = useAppSelector((state) => state.auth.user?.role.USER_CREATE !== 'NEVER');
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserListAction());
  }, [dispatch])

  return (
    <CardDataBlock
      title="Пользователи"
      extra={
        <>
          {hasAccessCreate && <ButtonCreateBlock onClick={() => navigate('/user-list/user-create')} />}
        </>
      }
      loading={user.status === 'loading'}
      noData={user.list.length === 0}
    >
      <TableContainer>
        <Table aria-label="users">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Пользователь</TableCell>
              <TableCell>Электронная почта</TableCell>
              <TableCell>Роль</TableCell>
              <TableCell>Подразделение</TableCell>
              <TableCell>Дата создания</TableCell>
              <TableCell>Дата обновления</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {user.list.map((item) => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={item.id}
              >
                <TableCell component="th" scope="row">
                  {item.id}
                </TableCell>
                <TableCell><Link to={'/user-list/' + item.id}>{item.name}</Link></TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.role.name}</TableCell>
                <TableCell>{item.department.name}</TableCell>
                <TableCell>{dateTranslate(item.createdAt)}</TableCell>
                <TableCell>{dateTranslate(item.updatedAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CardDataBlock>
  );
};

export { UserListFeature };
