import { createAsyncThunk } from '@reduxjs/toolkit';

import { AuthApi } from '@stores/auth/auth.api';
import { callNotifyAction } from '@stores/app/app.slice';
import { AuthStateInterface } from '@type/auth/auth-state.interface';
import { CurrentUserType } from '@type/auth/current-user.type';
import { SigninRequestInterface } from '@type/auth/signin-request.interface';
import { PersistanceService } from '@services/persistance.service';


export const signinAction = createAsyncThunk<
  CurrentUserType,
  SigninRequestInterface,
  { state: { auth: AuthStateInterface } }
>(
  '@@auth/signin',
  async (payload: SigninRequestInterface, { rejectWithValue, dispatch }) => {
    const response = await AuthApi.signin(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка авторизации!' }));
      return rejectWithValue(response.errors);
    }

    PersistanceService.setToken(response.data.user.token);

    return response.data.user;
  },
  {
    condition: (_, { getState }) => {
      const { auth } = getState();

      if (auth.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const currentUserAction = createAsyncThunk<
  CurrentUserType,
  undefined,
  { state: { auth: AuthStateInterface } }
>(
  '@@auth/current',
  async (_, { rejectWithValue }) => {
    const response = await AuthApi.current();
    
    if (!response.success) {
      return rejectWithValue(response.errors);
    }

    PersistanceService.setToken(response.data.user.token);

    return response.data.user;
  },
  {
    condition: (_, { getState }) => {
      const { auth } = getState();

      if (auth.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const signoutAction = createAsyncThunk<
  void,
  undefined,
  { state: { auth: AuthStateInterface } }
>(
  '@@auth/signout',
  async () => {
    PersistanceService.clearToken();
  },
);


