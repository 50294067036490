import { SigninFormFeature } from '@features/signin-form';

import styles from './signin.module.scss';


const SigninPage = () => {  
  return (
    <div className={styles['signin-page']}>
      <div className={styles['signin-page__form-container']}>
        <SigninFormFeature />
      </div>
    </div>
  );
};

export { SigninPage };
