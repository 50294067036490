import { createAsyncThunk } from '@reduxjs/toolkit';

import { callNotifyAction } from '@stores/app/app.slice';
import { DepartmentType } from '@type/department/department.type';
import { DepartmentStateInterface } from '@type/department/department-state.interface';

import { DepartmentApi } from './department.api';
import { CreateDepartmentRequestInterface } from '@type/department/create-department-request.interface';


export const getDepartmentListAction = createAsyncThunk<
  DepartmentType[],
  undefined,
  { state: { department: DepartmentStateInterface } }
>(
  '@@department/get-list',
  async (_, { rejectWithValue, dispatch }) => {
    const response = await DepartmentApi.getList();
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка получения данных!' }));
      return rejectWithValue(response.errors);
    }

    return response.data.departments;
  },
  {
    condition: (_, { getState }) => {
      const { department } = getState();

      if (department.status === 'loading') {
        return false;
      }

      return true;
    }
  },
);

export const getDepartmentDictionaryAction = createAsyncThunk<
  DepartmentType[],
  undefined,
  { state: { department: DepartmentStateInterface } }
>(
  '@@department/get-dictionary',
  async (_, { rejectWithValue, dispatch }) => {
    const response = await DepartmentApi.getDictionary();
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка получения данных!' }));
      return rejectWithValue(response.errors);
    }

    return response.data.departments;
  },
  {
    condition: (_, { getState }) => {
      const { department } = getState();

      if (department.status === 'loading') {
        return false;
      }

      return true;
    }
  },
);

export const getDepartmentOneAction = createAsyncThunk<
  DepartmentType,
  number,
  { state: { department: DepartmentStateInterface } }
>(
  '@@department/get-one',
  async (id: number, { rejectWithValue, dispatch }) => {
    const response = await DepartmentApi.getOne(id);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка получения данных!' }));
      return rejectWithValue(response.errors);
    }

    return response.data.department;
  },
  {
    condition: (_, { getState }) => {
      const { department } = getState();

      if (department.status === 'loading') {
        return false;
      }

      return true;
    }
  },
);

export const createDepartmentAction = createAsyncThunk<
  DepartmentType,
  CreateDepartmentRequestInterface,
  { state: { department: DepartmentStateInterface } }
>(
  '@@department/create',
  async (payload: CreateDepartmentRequestInterface, { rejectWithValue, dispatch }) => {
    const response = await DepartmentApi.create(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data.department;
  },
  {
    condition: (_, { getState }) => {
      const { department } = getState();

      if (department.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const updateDepartmentAction = createAsyncThunk<
  DepartmentType,
  DepartmentType,
  { state: { department: DepartmentStateInterface } }
>(
  '@@department/update',
  async (payload: DepartmentType, { rejectWithValue, dispatch }) => {
    const response = await DepartmentApi.update(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено обновлены!' }));

    return response.data.department;
  },
  {
    condition: (_, { getState }) => {
      const { department } = getState();

      if (department.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);
