import { TextField } from '@mui/material';

import { FormFieldBlock } from '@blocks/form-field';
import { AsteriskBlock } from '@blocks/asterisk';

import { FieldTextProps } from './field-text.props';


const FieldTextBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  required,
  counted,
}: FieldTextProps) => {
  return (
    <FormFieldBlock error={error}>
      <TextField
        label={<>{label}<AsteriskBlock required={required} counted={counted} /></>}
        name={name}
        value={value}
        onChange={onChange}
        type="text"
        variant="filled"
        fullWidth
      />
    </FormFieldBlock>
  );
};

export { FieldTextBlock };
