import { AccessType } from '@type/role/access.type';


export const accessTranslate = (access: AccessType): string => {
  if (access === 'ALL') return 'Да';
  if (access === 'NEVER') return 'Нет';
  if (access === 'ONLY_MY') return 'Только своих';
  if (access === 'ONLY_MY_DEPARTMENT') return 'Только своего отделения';
  
  return access;
}
