export class PersistanceService {
  public static setToken(token: string): void {
    try {
      localStorage.setItem('accessToken', JSON.stringify(token));
    } catch (error) {
      console.error(`Error saving to localStorage key - accessToken`, error);
    }
  };

  public static getToken(): string {
    try {
      return JSON.parse(localStorage.getItem('accessToken') || '');
    } catch (error) {
      // console.error(`Error reading from localStorage key - accessToken`, error);
      return '';
    }
  };

  public static clearToken(): void {
    try {
      localStorage.removeItem('accessToken');
    } catch (error) {
      console.error(`Error removing from localStorage key - accessToken`, error);
    }
  };
};
