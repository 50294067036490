import { ChangeEvent, SyntheticEvent, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { errorTranslate } from '@helpers/error-translate.helper';
import { DepartmentType } from '@type/department/department.type';
import { updateDepartmentAction } from '@stores/department/department.actions';
import { ButtonCancelBlock } from '@blocks/button-cancel';
import { FieldTextBlock } from '@blocks/field-text';
import { ButtonSaveBlock } from '@blocks/button-save';

import { DepartmentUpdateProps } from './department-update.props';
import styles from './department-update.module.scss';


const DepartmentUpdateFeature = ({ onDone }: DepartmentUpdateProps) => {
  const dispatch = useAppDispatch();
  const department = useAppSelector((state) => state.department);
  const [formValue, setFormValue] = useState<DepartmentType>(department.current!);
  const [formDirty, setFormDirty] = useState(false);

  const changeFormValue = (e: ChangeEvent<HTMLInputElement>): void => {
    setFormDirty(true);
    setFormValue((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();

    const result = await dispatch(updateDepartmentAction(formValue));

    if (result.type === '@@department/update/fulfilled') {
      onDone();
    }
  }

  const setError = (fieldName: string) => {
    return department.errors && department.errors[fieldName] && errorTranslate(department.errors[fieldName][0]);
  }

  return (
    <div className="row justify-content-lg-center">
      <div className="col col-lg-6">
        <div className={styles['department-update']}>
          <form onSubmit={(e: SyntheticEvent) => onSubmit(e)}>
            <FieldTextBlock
              name="name"
              label="Название"
              value={formValue.name}
              onChange={changeFormValue}
              error={setError('name')}
            />

            <ButtonSaveBlock
              loading={department.status === 'submitting'}
              onClick={onSubmit}
              disabled={!formDirty}
            />
            <ButtonCancelBlock onClick={() => onDone()} />
          </form>
        </div>
      </div>
    </div>
  );
};

export { DepartmentUpdateFeature };
