import { ChangeEvent, SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FieldTextBlock } from '@blocks/field-text';
import { ButtonBackBlock } from '@blocks/button-back';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';

import styles from './department-create.module.scss';
import { createDepartmentAction } from '@stores/department/department.actions';
import { errorTranslate } from '@helpers/error-translate.helper';
import { DepartmentType } from '@type/department/department.type';
import { ButtonSaveBlock } from '@blocks/button-save';


const DepartmentCreateFeature = () => {
  const [formValue, setFormValue] = useState({ name: '' });
  const [formDirty, setFormDirty] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const department = useAppSelector((state) => state.department);

  const changeFormValue = (e: ChangeEvent<HTMLInputElement>): void => {
    setFormDirty(true);
    setFormValue((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();

    const result = await dispatch(createDepartmentAction(formValue));

    if (result.type === '@@department/create/fulfilled') {
      navigate('/department-list/' + (result.payload as DepartmentType).id);
    }
  }

  const setError = (fieldName: string) => {
    return department.errors && department.errors[fieldName] && errorTranslate(department.errors[fieldName][0]);
  }
  
  return (
    <div className={styles['department-create']}>
      <form onSubmit={(e: SyntheticEvent) => onSubmit(e)}>
        <FieldTextBlock
          name="name"
          label="Название"
          value={formValue.name}
          onChange={changeFormValue}
          error={setError('name')}
        />

        <ButtonSaveBlock
          loading={department.status === 'submitting'}
          onClick={onSubmit}
          disabled={!formDirty}
        />
        <ButtonBackBlock />
      </form>
    </div>
  )
}

export { DepartmentCreateFeature };
