
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { ButtonCreateProps } from './button-create.props';


const ButtonCreateBlock = ({ onClick }: ButtonCreateProps) => {
  return (
    <Button
      color="primary"
      variant="outlined"
      startIcon={<AddIcon />}
      onClick={onClick}
      size="large"
    >
      Добавить
    </Button>
  );
};

export { ButtonCreateBlock };
