import { forwardRef, useEffect, useState } from 'react';

import { LinearProgress } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { useAppSelector } from '@hooks/redux.hooks';


const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NotifyFeature = () => {
  const [open, setOpen] = useState(false);
  const { notify } = useAppSelector((state) => state.app);

  useEffect(() => {
    if (notify.message) {
      setOpen(true);
    }
  }, [notify])

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
      <Alert onClose={() => setOpen(false)} severity={notify.type} sx={{ width: '100%' }}>
        { notify.message === 'loading' ? (
          <div style={{paddingTop: '8px', width: '200px'}}>
            <LinearProgress />
          </div>
        ) : notify.message }
      </Alert>
    </Snackbar>
  );
};

export { NotifyFeature };
