
import { useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

import { ButtonRemoveProps } from './button-remove.props';


const ButtonRemoveBlock = ({ onClick }: ButtonRemoveProps) => {
  const [opened, setOpened] = useState(false);

  const agreeHandler = () => {
    setOpened(false);
    onClick();
  }

  return (
    <>
      <IconButton onClick={() => setOpened(true)} aria-label="delete" size="large">
        <DeleteSweepIcon />
      </IconButton>
      <Dialog
        open={opened}
        onClose={() => setOpened(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Вы уверены?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Текущая запись, а также все связанные с ней записи будут удалены.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpened(false)} autoFocus>Отмена</Button>
          <Button onClick={agreeHandler}>
            Подтвержданю
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export { ButtonRemoveBlock };
