import { EntityType } from '@type/config/entity.type';

export const priemCherezGodEntity: EntityType = {
  key: 'priem_cherez_god',
  label: {
    singular: 'Прием через год',
    plural: 'Прием через год',
    genitive: 'Прием через год',
  },
  type: 'one',
  parent: 'card',
  columns: [
    {
      label: 'Дата визита',
      key: 'data-vizita',
      type: 'date',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      required: true,
    },
    {
      label: 'Рост, см',
      key: 'rost-sm',
      type: 'number',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      label: 'Вес, кг',
      key: 'ves-kg',
      type: 'number',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      label: 'Число родов',
      key: 'chislo-rodov',
      type: 'number',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      label: 'Число прерываний беременности',
      key: 'chislo-preryvanij-beremennosti',
      type: 'number',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      label: 'Беременна в настоящий момент',
      key: 'beremenna-v-nastoyaschij-moment',
      type: 'boolean',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      label: 'Дата начала последней менструации',
      key: 'data-nachala-poslednej-menstruacii',
      type: 'date',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      visibleRule: {
        fieldKey: 'beremenna-v-nastoyaschij-moment',
        equal: false,
      }
    },
    {
      label: 'Срок беременности (нед)',
      key: 'srok-beremennosti-ned',
      type: 'number',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      required: false,
      visibleRule: {
        fieldKey: 'beremenna-v-nastoyaschij-moment',
        equal: true,
      },
    },
    {
      label: 'Вакцинирована от ВПЧ',
      key: 'vakcinirovana-ot-vpch',
      type: 'boolean',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      label: 'Сроки последней вакцинации (лет)',
      key: 'sroki-poslednej-vakcinacii-let',
      type: 'number',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      required: false,
      visibleRule: {
        fieldKey: 'vakcinirovana-ot-vpch',
        equal: true,
      },
    },
    {
      label: 'Название вакцины',
      key: 'nazvanie-vakciny',
      type: 'select',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      required: false,
      variants: ['Церварикс', 'Гардасил', 'Другое'],
      visibleRule: {
        fieldKey: 'vakcinirovana-ot-vpch',
        equal: true,
      },
    },
    {
      label: 'Отношение к курению',
      key: 'otnoshenie-k-kureniyu',
      type: 'select',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      counted: true,
      variants: ['Не курила', 'Курила ранее (более 6 мес назад)', 'Курит в настоящее время'],
    },
    {
      label: 'Ключевой тип курения',
      key: 'klyuchevoj-tip-kureniya',
      type: 'select',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      required: false,
      variants: [
        'Сигареты',
        'Сигары',
        'Бездымные системы нагревания табака',
        'СЭДН (системы электронной доставки никотина)'
      ],
      visibleRule: {
        fieldKey: 'otnoshenie-k-kureniyu',
        oneOf: ['Курила ранее (более 6 мес назад)', 'Курит в настоящее время'],
      },
    },
    {
      label: 'Количество выкуриваемых единиц в день (сигарет, сигар, стиков)',
      key: 'kolichestvo-vykurivaemyh-edinic-v-den',
      type: 'number',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      required: false,
      visibleRule: {
        fieldKey: 'otnoshenie-k-kureniyu',
        oneOf: ['Курила ранее (более 6 мес назад)', 'Курит в настоящее время'],
      },
    },
    {
      label: 'Стаж курения, лет',
      key: 'stazh-kureniya-let',
      type: 'number',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      required: false,
      visibleRule: {
        fieldKey: 'otnoshenie-k-kureniyu',
        oneOf: ['Курила ранее (более 6 мес назад)', 'Курит в настоящее время'],
      },
    },
    {
      label: 'ВИЧ-инфицированная',
      key: 'vich-inficirovannaya',
      type: 'boolean',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      label: 'Прием антиретровирусной терапии',
      key: 'priem-antiretrovirusnoj-terapii',
      type: 'boolean',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      required: false,
      visibleRule: {
        fieldKey: 'vich-inficirovannaya',
        equal: true,
      },
    },
    {
      label: 'Компоненты АРТ',
      key: 'komponenty-art',
      type: 'multiselect',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      required: false,
      variants: [
        'Ингибиторы обратной транскриптазы',
        'Ингибиторы протеазы',
        'Ингибиторы интегразы',
        'Ингибиторы проникновения (слияния)',
        'Другое',
      ],
      visibleRule: {
        fieldKey: 'priem-antiretrovirusnoj-terapii',
        equal: true,
      },
    },
    {
      label: 'ИППП в анамнезе',
      key: 'ippp-v-anamneze',
      type: 'multiselect',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      counted: true,
      variants: [
        'Нет',
        'Хламидиоз',
        'ВПЧ',
        'Сифилис',
        'Гонорея',
        'Другое',
      ],
    },
    {
      label: 'Способ подтверждения диагноза ВПЧ в анамнезе',
      key: 'sposob-podtverzhdeniya-diagnoza-vpch-v-anamneze',
      type: 'select',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      required: false,
      variants: [
        'Клинический диагноз (осмотр)',
        'ПЦР-подтверждение',
        'Серологическое подтверждение',
      ],
      visibleRule: {
        fieldKey: 'ippp-v-anamneze',
        contains: ['ВПЧ'],
      },
    },
    {
      label: 'Группы возбудителей',
      key: 'gruppy-vozbuditelej',
      type: 'select',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      required: false,
      variants: [
        'Низкого риска',
        'Высокого риска',
      ],
      visibleRule: {
        fieldKey: 'sposob-podtverzhdeniya-diagnoza-vpch-v-anamneze',
        oneOf: ['ПЦР-подтверждение', 'Серологическое подтверждение'],
      },
    },
    {
      label: 'Выделенные генотипы',
      key: 'vydelennye-genotipy',
      type: 'multiselect',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      required: false,
      variants: [
        '16',
        '18',
        '31',
        '33',
        '35',
        '39',
        '45',
        '51',
        '52',
        '56',
        '58',
        '59',
        'Другие',
      ],
      visibleRule: {
        fieldKey: 'sposob-podtverzhdeniya-diagnoza-vpch-v-anamneze',
        oneOf: ['ПЦР-подтверждение', 'Серологическое подтверждение'],
      },
    },
    {
      label: 'Контрацепция',
      key: 'kontracepciya',
      type: 'multiselect',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      counted: true,
      variants: [
        'Нет',
        'КОК',
        'ВМК',
        'Барьерный метод',
        'Спермициды',
        'Другое',
      ],
    },
    {
      label: 'Прием антибактериальных препаратов в течение последних 30 дней',
      key: 'priem-antibakterialnyh-preparatov',
      type: 'select',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      counted: true,
      variants: [
        'Не принимала',
        'Принимала менее 30 дней назад',
        'Принимает сейчас',
      ],
    },
    {
      label: 'Показание к приему А/б препаратов',
      key: 'pokazanie-k-priemu-a-b-preparatov',
      type: 'text',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      required: false,
      visibleRule: {
        fieldKey: 'priem-antibakterialnyh-preparatov',
        oneOf: ['Принимала менее 30 дней назад', 'Принимает сейчас'],
      },
    },
    {
      label: 'Группа антибактериальных препаратов',
      key: 'gruppa-antibakterialnyh-preparatov',
      type: 'multiselect',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      required: false,
      variants: [
        'Азолы',
        'Аминогликозиды',
        'Амфениколы',
        'Макролиды/азалиды',
        'Пенициллины',
        'Сульфаниламиды',
        'Фосфомицины',
        'Фторхинолоны',
        'Цефалоспорины',
        'Другие',
      ],
      visibleRule: {
        fieldKey: 'priem-antibakterialnyh-preparatov',
        oneOf: ['Принимала менее 30 дней назад', 'Принимает сейчас'],
      },
    },
    {
      label: 'Дата начала приема антибактериальных',
      key: 'data-nachala-priema-antibakterialnyh',
      type: 'date',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      required: false,
      visibleRule: {
        fieldKey: 'priem-antibakterialnyh-preparatov',
        oneOf: ['Принимала менее 30 дней назад', 'Принимает сейчас'],
      },
    },
    {
      label: 'Дата завершения приема антибактериальных',
      key: 'data-zaversheniya-priema-antibakterialnyh',
      type: 'date',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      required: false,
      visibleRule: {
        fieldKey: 'priem-antibakterialnyh-preparatov',
        oneOf: ['Принимала менее 30 дней назад', 'Принимает сейчас'],
      },
    },
    {
      label: 'Применение противогрибковых препаратов в течение последних 30 дней',
      key: 'primenenie-protivogribkovyh-preparatov',
      type: 'select',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      counted: true,
      variants: [
        'Не применяла',
        'Да',
        'Применяет сейчас',
      ],
    },
    {
      label: 'Показания',
      key: 'pokazaniya',
      type: 'text',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      required: false,
      visibleRule: {
        fieldKey: 'primenenie-protivogribkovyh-preparatov',
        oneOf: ['Да', 'Применяет сейчас'],
      },
    },
    {
      label: 'МНН препарата',
      key: 'mnn-preparata',
      type: 'text',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      required: false,
      visibleRule: {
        fieldKey: 'primenenie-protivogribkovyh-preparatov',
        oneOf: ['Да', 'Применяет сейчас'],
      },
    },
    {
      label: 'Дата начала приема противогрибковых',
      key: 'data-nachala-primeneniya-protivogribkovyh',
      type: 'date',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      required: false,
      visibleRule: {
        fieldKey: 'primenenie-protivogribkovyh-preparatov',
        oneOf: ['Да', 'Применяет сейчас'],
      },
    },
    {
      label: 'Дата завершения приема противогрибковых',
      key: 'poslednij-priem-protivogribkovyh',
      type: 'date',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      required: false,
      visibleRule: {
        fieldKey: 'primenenie-protivogribkovyh-preparatov',
        oneOf: ['Да', 'Применяет сейчас'],
      },
    },
    {
      label: 'Патология ШМ в анамнезе',
      key: 'patologiya-shm-v-anamneze',
      type: 'multiselect',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      counted: true,
      variants: [
        'Нет',
        'Дисплазия',
        'Лейкоплакия',
        'Эрозии',
        'Другое',
      ],
    },
    {
      label: 'Лечение ШМ в анамнезе',
      key: 'lechenie-shm-v-anamneze',
      type: 'multiselect',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      counted: true,
      variants: [
        'Нет',
        'Лазерная коагуляция',
        'Криокоагуляция',
        'Диатермоэлектрокоагуляция',
        'Петлевая эксцизия',
        'Фотодинамическая терапия',
        'Другое',
      ],
    },
    {
      label: 'Дата проведения процедуры',
      key: 'data-provedeniya-procedury',
      type: 'date',
      display: {
        list: true,
        one: true,
        link: false,
        create: true,
        update: true,
      },
      counted: true,
      visibleRule: {
        fieldKey: 'lechenie-shm-v-anamneze',
        oneOf: [
          'Лазерная коагуляция',
          'Криокоагуляция',
          'Диатермоэлектрокоагуляция',
          'Петлевая эксцизия',
          'Фотодинамическая терапия',
          'Другое',
        ],
      },
    },
  ]
}
